// Añadido: Definir las subcolumnas para cada columna principal
export const subColsMap = {
  Sumas: ['debe', 'haber'],
  Saldos: ['deudor', 'acreedor'],
  Inventario: ['activo', 'pasivo'],
  Resultado: ['perdida', 'ganancia']
}

export const styles = {
  table: 'w-full text-[#000231] text-xs border-collapse leading-6 table-fixed',
  tr: 'hover:bg-gray-100/50',
  td: 'text-left align-top p-4 whitespace-nowrap max-w-52 overflow-x-auto no-scrollbar-space-y-1',
  th: 'border-2 border-transparent border-b-gray-200 text-left p-4 whitespace-nowrap bg-white',
  thead: 'sticky top-0 z-10',
  tbody: 'overflow-y-auto',
  boldText: 'font-bold',
  text: 'text-xs text-gray-500'
}

export const orderableCols = [
  { col: 'Fecha', field: 'fecha' },
  // { col: 'Tipo', field: 'nombreTipoComprobante' },
  // { col: 'Comprobante', field: 'id' },
  // { col: 'Cuenta', field: 'nombreCuenta' },
  { col: 'Glosa', field: 'glosa' },
  { col: 'Debe', field: 'debe' },
  { col: 'Haber', field: 'haber' }
]
