import { useEffect } from 'react'
import {Paper, SearchInput} from '../../components/modules/ui/index'

//assets
import StarIcon from '@mui/icons-material/StarOutlineOutlined'
import StarFullIcon from '@mui/icons-material/StarOutlined'

//data
import { arrayIcons } from './reporteIcon'

// hooks
import { useContentTypeReportes } from './useReportes.hook'
import TooltipIcons from './TooltipIcons'

const NavBar = () => {
  const styleImage = 'cursor-pointer w-8 h-8'
  const setTypeContent = useContentTypeReportes(state => state.setTypeContent)
  const addFavoriteIcon = useContentTypeReportes(state => state.addFavoriteIcon)
  const removeFavoriteIcon = useContentTypeReportes(state => state.removeFavoriteIcon)
  const clickedIcons = useContentTypeReportes(state => state.clickedIcons)
  const resetTypeContent = useContentTypeReportes(state => state.resetTypeContent)

  useEffect(() => {
    resetTypeContent()
  }, [resetTypeContent])

  const handleIconClick = (unIcons) => {
    setTypeContent(unIcons.id)
  }

  const handleStarClick = (e, unIcons) => {
    e.stopPropagation()
    if (clickedIcons.has(unIcons.id)) {
      removeFavoriteIcon(unIcons.id)
    } else {
      addFavoriteIcon(unIcons)
    }
  }
  
    return (
      <Paper>
        <section className='flex items-center justify-end'>
          <SearchInput placeholder='Buscar reporte' classNameBorder='rounded-full'></SearchInput>
        </section>
        <section className='grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 py-4 max-w-screen-lg mx-auto place-items-center'>
          {arrayIcons.map((unIcons, index) => (
            <div
              key={index}
              className='flex flex-col items-center justify-center shadow-md border rounded-2xl w-52 h-52 aspect-square'
              onClick={() => handleIconClick(unIcons)}
            >
              <div className='flex items-center justify-between w-full px-20'>
                {unIcons.tooltip && <TooltipIcons tooltipText={unIcons.tooltip} />}
                {clickedIcons.has(unIcons.id) ? (
                  <StarFullIcon className={styleImage} style={{ color: 'orange' }}
                    onClick={(e) => handleStarClick(e, unIcons)} alt='icon' />
                ) : (
                  <StarIcon className={styleImage} style={{ color: '#a1a1aa' }}
                    onClick={(e) => handleStarClick(e, unIcons)} alt='icon' />
                )}
              </div>
              <img className='w-20 h-20 cursor-pointer' src={unIcons.icon} alt={unIcons.title} />
              <p className='text-center text-xs'>{unIcons.title}</p>
            </div>
          ))}
        </section>
      </Paper>
    )
}

export default NavBar