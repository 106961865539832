import  { PureComponent } from "react";
import {
    BarChart,
    Bar,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
    ResponsiveContainer,
} from "recharts";


// Definimos la interfaz de las props
interface Props {
    rawData?: {
        porPagar: number;
        porCobrar: number;
    };
}

export default class BarChartComponent extends PureComponent<Props> {
    render() {
        const { rawData } = this.props;

        // Datos predeterminados en caso de que no lleguen datos
        const defaultData = [
            {
                name: "Cuentas",
                pagar: 189478425,
                cobrar: 102080760,
            },
        ];

        // Si `rawData` está vacío o no definido, usa los datos predeterminados
        const chartData = rawData
            ? [
                {
                    name: "Cuentas",
                    pagar: rawData.porPagar || 0,
                    cobrar: rawData.porCobrar || 0,
                },
            ]
            : defaultData;

        return (
            <ResponsiveContainer width="100%" height={300}>
                <BarChart
                    data={chartData}
                    margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
                    barCategoryGap={10} // Espacio entre barras
                    barSize={80} // Grosor de las barras
                >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="name" />
                    <YAxis />
                    <Tooltip formatter={(value) => `$ ${value.toLocaleString()}`} />
                    <Legend />
                    <Bar dataKey="pagar" fill="#1F77B4" name="Facturas por pagar" />
                    <Bar dataKey="cobrar" fill="#2CA02C" name="Facturas por cobrar" />
                </BarChart>
            </ResponsiveContainer>
        );
    }
}