//components
import { VisionDelNegocio } from '../components/modules/views/dashboard/BusinessInsights/VisionDelNegocio/VisionDelNegocio'
import Tabs from '../components/modules/tabs/Tabs'
import Presupuesto from '../components/modules/views/dashboard/BusinessInsights/Presupuesto/Index'
import Reportes from '../components/modules/views/dashboard/BusinessInsights/Reportes/Reportes'
import Layout from '../components/layouts/index'
import { useEffect, useState } from 'react'
import { Dialog } from '@mui/material'
import CustomButton from '../components/modules/ui/CustomButton'
import Welcome from './Welcome'

const data = [
  {
    title: 'Visión del negocio',
    content: <VisionDelNegocio />
  },
  {
    title: 'Presupuesto',
    content: <Presupuesto />
  },
  {
    title: 'Reportes',
    content: <Reportes />
  }
]

/**
 *
 * @returns {JSX.Element}
 */
export default function BusinessInsights() {
  const [showPopup, setShowPopup] = useState(false)

  useEffect(() => {
    if (localStorage.getItem('showWelcomePopup')) {
      setShowPopup(true);
      localStorage.removeItem('showWelcomePopup'); // Eliminar para que no se muestre en recargas futuras
    }
  }, [])

  return (
    <Layout>
      <div className=''>
        <Tabs tabs={data} />
      </div>
      {showPopup && (
        <Dialog open={showPopup} onOpenChange={setShowPopup}>
        <div className='p-8 bg-white rounded-3xl shadow-2xl max-w-4xl mx-auto'>
          <h2 className='text-3xl font-bold text-center'>¡Bienvenido a Luca!</h2>
          <p className='mt-4 text-gray-600 text-center'>
            Aquí encontrarás un tutorial acerca del sistema.
          </p>
          <div className='mt-6'>
            <Welcome />
          </div>
          <div className='flex justify-center gap-4 mt-6'>
            <CustomButton className='px-6 rounded-full' color='primary' title='Cerrar' onClick={() => setShowPopup(false)}/>
            <CustomButton className='px-6 rounded-full' color='accent2' title='Omitir'/>
          </div>
        </div>
      </Dialog>
      )}
    </Layout>
  )
}
