import { Form, Formik } from 'formik'
import { useLucaSignUp } from '../hooks/useLucaSignUp'
import { FieldWithErrorMessage } from '../../../ui/FieldWithErrorMsg'
import * as Yup from 'yup'
import { useSwiper } from 'swiper/react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight } from '@fortawesome/free-solid-svg-icons'
import HeelpIcon from '@mui/icons-material/HelpOutlineOutlined'

const step2ValidationSchema = Yup.object({
  companyName: Yup.string().required('Ingresa el nombre de tu empresa')
})

/**
 *
 * @returns {JSX.Element} The rendered component.
 */

const HelpIcon = () => {
  return (
    <div className='relative group'>
      <HeelpIcon />
      <div className='absolute hidden group-hover:flex items-center justify-center bg-primary text-white text-sm rounded-lg py-2 px-3 top-full mt-2 right-0 w-64 max-w-none z-50'>
        La organización es el contenedor de tus empresas, más adelante puede agregarlas o gestionarlas aquí.
      </div>
    </div>
  )
}

export function StepForm2() {
  const addAccountProfileValues = useLucaSignUp(state => state.setAccountProfile)
  const accountProfile = useLucaSignUp(state => state.accountProfile)
  const swiper = useSwiper()

  const step2Values = {
    companyName: accountProfile?.companyName ?? ''
  }

  /**
   *
   * @param {import('../part1/FormCreateUser').Step1Values} values
   * @param {import('formik').FormikHelpers<import('../part1/FormCreateUser').Step1Values>} actions
   */
  const handleNext = (values, actions) => {
    addAccountProfileValues(values)
    swiper.slideNext()
    actions.setSubmitting(true)
  }

  return (
    <Formik
      validationSchema={step2ValidationSchema}
      initialValues={step2Values}
      onSubmit={handleNext}
    >
      <Form className='relative px-1 pt-8'>
        <div className='absolute top-0 right-2'>
            <HelpIcon />
          </div>
          <div className='relative'><h3 className='mb-8 text-2xl font-bold text-center'>¿Cómo se llamara tu organización?</h3></div>
        <ul className='grid grid-cols-2 gap-x-8 gap-y-3 mt-6 w-3/4 mx-auto py-2'>
          <li className='col-span-2'>
            <FieldWithErrorMessage
              name='companyName'
              type='text'
              placeholder='Ingresa un nombre'
            />
          </li>
        </ul>
        <div className='flex flex-col gap-2 mt-12 w-2/3 mx-auto'>
          <div>
            <button type='submit' className='btn-primary rounded-full'>
              <span>Continuar</span>
              <FontAwesomeIcon size='lg' icon={faArrowRight} />
            </button>
          </div>
        </div>
      </Form>
    </Formik>
  )
}
