import { useRef, useState } from 'react'
import iconLapiz from '../../../../../assets/iconos/iconLapiz.png'
import * as Yup from 'yup'
//assets
import tituloLoginSII from '../../../../../assets/tituloLoginSII.png'
//components
import { Form, Formik } from 'formik'
import { FieldWithErrorMessage } from '../../../../modules/ui/FieldWithErrorMsg'
import { REACT_APP_BASE_URL } from '../../../../utils/constant'
import { toast } from 'react-toastify'
// utils
import { formatRutForBackend } from '../../../../utils/formatter'
import { useGlobalContext } from '../../../../../context/GlobalState'
import { useMutationCreateBusiness } from '../../../../../queries/businessQueries'
import { BaseHTTP } from '../../../../../api/base.http'

/**
 *
 * @returns {JSX.Element} The rendered component.
 */
export const AddNewCompany = ({onClose }) => {
  const createBusiness = useMutationCreateBusiness()
  const { business, setCurrentBusiness, setBusiness } = useGlobalContext()
  // const { token } = UserAuth()
  const [imageUrl, setImageUrl] = useState('https://placehold.co/400')
  const imgFileRef = useRef(null)

  const schemmaValidation = Yup.object().shape({
    user: Yup.string()
      .required('Ingresa tu RUT')
      .test(
        'unique-user',
        'Este RUT ya está en uso',
        value => !business.find(b => b.rut === formatRutForBackend(value))
      ),
    password: Yup.string().required('Ingresa tu clave tributaria'),
    nombreFantasia: Yup.string().required('Ingresa el nombre de la empresa')
  })

  const handleSubmitForm = async (values, actions) => {
    try {
      await toast.promise(
        handleCreateBusiness(values),
        {
          pending: {
            render: () => 'Agregando Empresa',
            className: 'bg-accent2 text-white',
          },
          success: {
            render: () => 'Empresa agregada exitosamente',
            className: 'bg-accent2 text-white',
          },
          error: {
            render(err) {
              const myError = err.data;
              console.log(myError.message);
              return `No se pudo crear correctamente 🤯`;
            },
            className: 'bg-accent1 text-white',
          },
        }
      )

      await toast.promise(
        Promise.all([
          new Promise((resolve) => setTimeout(resolve, 2000)),
          BaseHTTP.getInstance().http.get(`${REACT_APP_BASE_URL}/v1/business`)
            .then(({ data }) => {
              setCurrentBusiness(data[0]);
              setBusiness(data);
            }),
        ]),
        {
          success: `Lista de empresas actualizada 👌`,
          error: `No se pudo actualizar lista de empresas 🤯`,
        }
      )
      actions.resetForm()
      actions.setSubmitting(true)
      onClose()
    } catch (error) {
      console.error('Error al intentar insertar en la base de datos:', error)
    }
  }

  const handleCreateBusiness = async values => {
    try {
      const createdBusiness = await createBusiness.mutateAsync(values)
      imgFileRef.current && (await updateImageByBusinessId(imgFileRef.current, createdBusiness.id))
      setImageUrl('https://placehold.co/400')
    } catch (error) {
      throw new Error(error.message)
    }
  }

  /**
   *
   * @param {File} imgFile
   */
  const updateImageByBusinessId = async (imgFile, bussinessID) => {
    const formData = new FormData()
    formData.append('file', imgFile)
    const authHeaders = {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    }
    try {
      // 1. post image
      const { data: imageData } = await BaseHTTP.getInstance().http.post(
        `${REACT_APP_BASE_URL}/v1/images`,
        formData,
        authHeaders
      )
      // 2. patch business
      await BaseHTTP.getInstance().http.patch(
        `${REACT_APP_BASE_URL}/v1/business/${bussinessID}/image`,
        { imageId: imageData.id }
      )
    } catch (error) {
      throw new Error(error.message)
    }
  }

  /**
   *
   * @param {import('react').ChangeEvent} e
   * @returns {Promise<void>}
   */
  const handleImageUpload = async e => {
    try {
      /** @type {File} */
      const imgFile = e.target.files[0]
      const imgUrl = await readImageFileAsUrl(imgFile)
      setImageUrl(imgUrl)
      imgFileRef.current = imgFile
    } catch (error) {
      return null
    }
  }

  /**
   *
   * @param {File} imgFile
   * @returns {Promise<string | null>} The image file as a base64 string.
   */
  const readImageFileAsUrl = async imgFile => {
    if (imgFile.type && !imgFile.type.startsWith('image/')) {
      throw new Error('File is not an image')
    }
    const reader = new FileReader()

    const readImgFile = new Promise((resolve, reject) => {
      reader.addEventListener(
        'loadend',
        e => {
          const result = e.target?.result
          if (!result) {
            return reject(null)
          }
          resolve(result)
        },
        { once: true }
      )
    })
    reader.readAsDataURL(imgFile) // start reading the the data and trigger the loadend event

    return await readImgFile
  }

  const AddCompanyForm = () => (
    <Formik
      validationSchema={schemmaValidation}
      initialValues={{ user: '', password: '', nombreFantasia: '' }}
      onSubmit={handleSubmitForm}
    >
      {({ isSubmitting }) => (
        <Form>
          <FieldWithErrorMessage
            type='text'
            name='nombreFantasia'
            label='Nombre Fantasía'
            placeholder='¿Cómo te gustaría que te llamemos?'
          />

          <div className='shadow-md my-3 rounded-b-md'>
            <div className='mb-2  flex justify-center bg-primary h-[74px] py-3'>
              <img src={tituloLoginSII} alt='tituloLoginSII' />
            </div>

            <ul className='flex flex-col gap-3 px-6 pb-6'>
              <li>
                <FieldWithErrorMessage
                  type='text'
                  name='user'
                  label='RUT'
                  placeholder='Ej: 123456789'
                />
              </li>
              <li>
                <FieldWithErrorMessage
                  type='password'
                  name='password'
                  label='Ingresar Clave Tributaria'
                  placeholder='Ingresa tu clave tributaria'
                />
              </li>
              <li>
                <button disabled={isSubmitting} type='submit' className='btn-primary text-sm'>
                  AGREGAR EMPRESA
                </button>
              </li>
            </ul>
          </div>
        </Form>
      )}
    </Formik>
  )

  const UploadPhoto = () => (
    <>
      <input onChange={handleImageUpload} className='hidden' id='upload-photo' type='file' />

      <div className='mt-6 flex flex-col items-center relative max-w-screen-sm mx-auto px-4'>
        <figure
          className='cursor-pointer relative'
          onClick={() => document.getElementById('upload-photo').click()}
        >
          <img
            className='rounded-full w-24 h-24 relative object-cover'
            src={imageUrl || 'https://placehold.co/400'}
            alt='placeholder'
          />

          <img
            src={iconLapiz}
            alt='iconLapiz'
            className='bottom-0 -right-4 absolute z-10 bg-white rounded-full w-9 h-9 shadow-md'
          />
        </figure>
      </div>
    </>
  )

  return (
    <section className='py-2'>
      <h3 className='font-bold text-xl mb-4 text-center'>Agregar nueva empresa</h3>

      <div className='grid grid-cols-2 pl-1'>
        <AddCompanyForm />

        <UploadPhoto />
      </div>
    </section>
  )
}
