import { PDFDownloadLink, Document, Page, Text, View, StyleSheet } from '@react-pdf/renderer'

const cols = [
  { header: 'N° Cuenta', width: 80 },
  { header: 'Nombre Cuenta', width: 120 },
  { header: 'Debe', width: 80 },
  { header: 'Haber', width: 80 },
  { header: 'Deudor', width: 80 },
  { header: 'Acreedor', width: 80 },
  { header: 'Activo', width: 80 },
  { header: 'Pasivo', width: 80 },
  { header: 'Perdida', width: 80 },
  { header: 'Ganancia', width: 80 }
]

const styles = StyleSheet.create({
  page: {
    flexDirection: 'row'
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1
  },
  table: {
    display: 'table',
    width: 'auto',
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: '#bfbfbf',
    marginVertical: '16px',
    marginHorizontal: 'auto',
    fontSize: 8
  },
  row: {
    flexDirection: 'row',
    borderBottomWidth: 1,
    borderBottomColor: '#000',
    alignItems: 'center'
  },
  col: {
    height: '100%',
    borderRightWidth: 1,
    borderRightColor: '#000',
    padding: 4
  },
  boldText: {
    fontWeight: '700'
  },
  text: {
    fontSize: 5,
    color: '#5a5a5a'
  }
})

const ExportPDFReportesLibroDiario = ({ data, title, year, currentBusiness, rut }) => {
  const MyDocument = () => {
    const rowsPerPage = 10 // Ajusta este valor según la cantidad de filas que caben en una página

    const pageCount = Math.ceil(data.length / rowsPerPage)

    return (
      <Document>
        {[...Array(pageCount)].map((_, pageIndex) => (
          <Page key={pageIndex} orientation='landscape' size='A4' style={styles.page}>
            <View style={styles.section}>
              {pageIndex === 0 && (
                <Text> {`Resumen de movimientos ${currentBusiness} ${rut}`}</Text>
              )}
              <View style={styles.table}>
                <View style={{ ...styles.row, backgroundColor: '#FFFFE0' }}>
                  {cols?.map((col, i) => (
                    <View style={{ ...styles.col, width: col.width, fontWeight: 'bold' }} key={i}>
                      <Text style={styles.boldText}>{col.header}</Text>
                    </View>
                  ))}
                </View>
                {data
                  .slice(pageIndex * rowsPerPage, (pageIndex + 1) * rowsPerPage)
                  .map((row, i) => (
                    <View key={i} style={styles.row}>
                      <View style={{ ...styles.col, width: 80 }}>
                        <Text style={styles.boldText}>{row.numCuenta}</Text>
                      </View>
                      <View style={{ ...styles.col, width: 120 }}>
                        <Text style={styles.boldText}>{row.nameCuenta}</Text>
                      </View>
                      <View style={{ ...styles.col, width: 80 }}>
                        <Text style={styles.text}>{row.debe.toLocaleString('es-CL')}</Text>
                      </View>
                      <View style={{ ...styles.col, width: 80 }}>
                        <Text style={styles.text}>{row.haber.toLocaleString('es-CL')}</Text>
                      </View>
                      <View style={{ ...styles.col, width: 80 }}>
                        <Text style={styles.text}>{row.deudor.toLocaleString('es-CL')}</Text>
                      </View>
                      <View style={{ ...styles.col, width: 80 }}>
                        <Text style={styles.text}>{row.acreedor.toLocaleString('es-CL')}</Text>
                      </View>
                      <View style={{ ...styles.col, width: 80 }}>
                        <Text style={styles.text}>{row.activo.toLocaleString('es-CL')}</Text>
                      </View>
                      <View style={{ ...styles.col, width: 80 }}>
                        <Text style={styles.text}>{row.pasivo.toLocaleString('es-CL')}</Text>
                      </View>
                      <View style={{ ...styles.col, width: 80 }}>
                       <Text style={styles.text}>{row.perdida.toLocaleString('es-CL')}</Text>
                      </View>
                      <View style={{ ...styles.col, width: 80 }}>
                        <Text style={styles.text}>{row.ganancia.toLocaleString('es-CL')}</Text>
                      </View>
                    </View>
                  ))}
              </View>
            </View>
          </Page>
        ))}
      </Document>
    )
  }

  if (!data) return null
  return (
    <PDFDownloadLink
      className='flex items-center justify-center w-10 h-10 text-red-700 font-bold tracking-tighter cursor-pointer rounded-full shadow-full-sm bg-white'
      orientation='landscape'
      document={<MyDocument />}
      fileName={`${title}_${currentBusiness}_${rut}_${year}.pdf`}
    >
      PDF
    </PDFDownloadLink>
  )
}

export default ExportPDFReportesLibroDiario
