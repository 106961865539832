import { Backdrop } from "@mui/material";

const Loader = ({ visible }) => {
    if (!visible) return null;
  
    return (
      <Backdrop sx={{ zIndex: 1600 }} open={true}>
        <div className="bg-gray-200 bg-opacity-50 rounded-lg p-6 flex items-center justify-center shadow-lg">
          <div className="loader-spinner border-4 border-t-accent2 border-gray-200 rounded-full w-16 h-16 animate-spin"></div>
        </div>
      </Backdrop>
    )
}

export default Loader