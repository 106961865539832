import { useState, forwardRef, Fragment, useEffect, useContext } from 'react'
import GlobalContext from '../../../context/global-context'
import { toast } from 'react-toastify'

//assets
import imgAbono from '../../../assets/iconos/icos28.png'
import imgCargo from '../../../assets/iconos/icos29.png'
import iconCalendario from '../../../assets/iconos/iconCalendario.png'
import {ReactComponent as UpsIcon} from '../../../assets/iconosVinculaciones/16.svg'
//api
import { OrganizationAPI } from '../../../api/organization.api'
import { BankAPI } from '../../../api/bancos.api'
import { BusinessAPI } from '../../../api/business.api'

// components
import { Button, Text, Title } from '../ui/index'
import Modal from './ModalDialogAddProps'
import { formatDate, formatCurrency } from '../../utils/formatter'
//import {typeMovementsAbono, typeMovementsCargo} from '../data/dataBanco'
import { bankIcons } from '../../modules/views/dashboard/Administracion/bancos/data'
import imgDefaultBank from '../../../assets/iconos/iconCasa.png'

// material
import ClearIcon from '@mui/icons-material/Clear'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import Slide from '@mui/material/Slide'
import CustomButton from '../ui/CustomButton'
import ModalBancoCreateVinculacion from '../views/dashboard/Administracion/bancos/modalDialogCreate/ModalBancoCreateVinculacion'
import TableConciliado from './TableConciliado'

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction='up' ref={ref} {...props} />
})


const ModalDialogMotionEditForm = ({
  open,
  setOpen,
  libroId,
  // status,
  setSwitchGetData
  // title
}) => {
  const [modalOpen, setModalOpen] = useState(false)
  const [data, setData] = useState({})
  const [dataCustom, setDataCustom] = useState({ typeMovement: '1' })
  const [dataCuentas, setDataCuentas] = useState([])
  const { currentBusiness } = useContext(GlobalContext)
  const organizationAPI = new OrganizationAPI()
  const bancoAPI = new BankAPI()
  const businessAPI = new BusinessAPI()
  const [openModalCreate, setOpenModalCreate] = useState(false)

  const handleClose = () => {
    setOpen(false)
  }

  const functionGetDataLibroContable = async () => {
    try {
      // ui.setLoader({ visible: true, text: 'cargando datos' })
      const response = await bancoAPI.getDataLibros(currentBusiness?.id, libroId)
      // setSwitchDate(false)
      setData(response.data)
    } catch (error) {
      console.error(error)
    } finally {
      // ui.setLoader({ visible: false, text: '' })
    }
  }

  const toastMessage = async () => {
    await toast.promise(functionGetDataLibroContable(), {
      pending: 'Cargando datos... 🚀',
      success: 'Datos cargados exitosamente... 👌',
      error: 'No se pudieron cargar los datos 🤯'
    })
  }

  const handleSubmit = async () => {
    try {
      const updateData = {
        cuentaContableId: null,
        glosa: 'glosa',
        validation: 1,
        vencimiento: 'vencimiento'
      }
      const response = await organizationAPI.patchDataLibroContable(
        currentBusiness?.id,
        libroId,
        updateData
      )
      console.info(response)
      setSwitchGetData(true)
      functionGetDataLibroContable()
    } catch (error) {
      console.error(error)
    }
  }

  const toastMessageEdit = async () => {
    await toast.promise(handleSubmit(), {
      pending: 'Editando datos... 🚀',
      success: 'Datos Editandos exitosamente... 👌',
      error: 'No se pudieron Editanditar los datos 🤯'
    })
  }

  useEffect(() => {
    toastMessage()
  }, [])

  // useEffect(() => {
  //   console.log(dataCustom)
  // }, [dataCustom])

  // useEffect(() => {
  //   console.log(data)
  // }, [data])

  useEffect(() => {
    const dataDetalleSelect = async () => {
      try {
        // ui.setLoader({ visible: true, text: 'cargando datos' })
        let params = {
          businessId: currentBusiness.id,
          limit: 100,
          type: data.categoriaTipoMovimiento === 'abono' ? 'boleta' : 'factura',
          state: 'por-pagar',
          order: 'ASC'
        }

        const responseCuentas = await businessAPI.getPorCobrarCobradas(params)
        // setSwitchDate(false)
        setDataCuentas(responseCuentas.data.data)
      } catch (error) {
        console.error(error)
      } finally {
        // ui.setLoader({ visible: false, text: '' })
      }
    }
    dataDetalleSelect()
  }, [data])

  console.log(dataCuentas, setDataCustom)

  /*const handleOptionSelect = e => {
    const { name, value } = e.target
    // if (name === 'typeMovement')
    setDataCustom({ ...dataCustom, [name]: value })
    // else {
    //   setDataCustom({ ...dataCustom, [name]: value })
    // }
    // if (name === 'idCuenta') return setData({ ...data, [name]: value })
    // if (name === 'validation') return setData({ ...data, [name]: value })

    // setDataCustom({ [name]: value })
  }*/

  /*const typeMovements =
    data.categoriaTipoMovimiento === 'abono' ? typeMovementsAbono : typeMovementsCargo*/

  const handleOpen = () => setOpenModalCreate(true)

  return (
    <div>
      <Fragment>
        <Dialog
          open={open}
          TransitionComponent={Transition}
          keepMounted
          onClose={handleClose}
          maxWidth='lg'
          scroll='body'
          PaperProps={{
            sx: {
              backgroundColor: '#f2f2f2' // Aquí aplicamos el borderRadius al contenido
            }
          }}
        >
          <DialogContent>
            <div className='bg-[#f2f2f2] flex justify-end items-center'>
              <button type='button' onClick={handleClose}>
                <ClearIcon sx={{ fontSize: 40 }} />
              </button>
            </div>
            <div className='px-10'>
              <Title className='text-3xl mb-10'>Editar movimiento</Title>
              {data.status === 'PENDIENTE' ? (
              <>
                <section className='flex flex-col bg-white shadow-5xl px-8 py-2 rounded-2xl gap-10'>
                <section className='flex justify-between items-center'>
                  <div className='flex'>
                    <img src={bankIcons[data.nombreBanco] || imgDefaultBank} className='min-w-10 h-10 mr-3'/>
                    <div>
                      <p className='text-lg font-bold uppercase'>{data.nombreBanco}</p>
                      <p className='text-sm uppercase'>{data.numeroCuentaBanco}</p>
                    </div>
                  </div>
                  <div className='flex items-center justify-end gap-3'>
                    <div className='flex flex-col items-end'>
                      <p className='text-lg font-bold uppercase'>{data.categoriaTipoMovimiento}</p>
                      <p className='text-lg font-bold uppercase'>{formatCurrency(data.montoTotal)}</p>
                    </div>
                    <img className='w-12 h-12' src={data.categoriaTipoMovimiento === 'abono' ? imgAbono : imgCargo} />
                  </div>
                </section>
                <section className='flex justify-between items-center gap-96'>
                  <div>
                    <p className='text-lg uppercase'>{data.descripcion}</p>
                    <div className='flex justify-rigt items-center gap-2'>
                      <img className='w-8 h-8' src={iconCalendario} alt='' />
                      <p className='text-lg font-bold uppercase text-center'>
                        {formatDate(data.fecha)}
                      </p>
                    </div>
                  </div>
                  <Text
                    className={
                      data.status === 'PENDIENTE'
                        ? 'text-accent3 font-bold text-xl'
                        : 'text-accent2 font-bold text-xl'
                    }
                  >
                    {data.status}
                  </Text>
                </section>
              </section>
              <section className='flex flex-col bg-gray-100 shadow-5xl px-8 py-6 rounded-2xl gap-6 mt-10'>
                <div className='flex flex-col items-center text-center'>
                  <UpsIcon className='w-14 h-14 text-gray-500 mt-10' />
                  <p className='text-lg uppercase text-gray-600 mt-6'>Upsss</p>
                  <p className='text-sm text-gray-500 mb-16'>
                    No pudimos encontrar buenos match para este movimiento en el banco, a ver si tú puedes :)
                  </p>
                </div>
              </section>
              </>
              ) : (
                <>
                <section className='flex flex-col bg-white shadow-5xl px-8 py-2 rounded-2xl gap-10'>
                <section className='flex justify-between items-center'>
                  <div className='flex'>
                    <img src={bankIcons[data.nombreBanco] || imgDefaultBank} className='min-w-10 h-10 mr-3'/>
                    <div>
                      <p className='text-lg font-bold uppercase'>{data.nombreBanco}</p>
                      <p className='text-sm uppercase'>{data.numeroCuentaBanco}</p>
                    </div>
                  </div>
                  <div className='flex items-center justify-end gap-3'>
                    <div className='flex flex-col items-end'>
                      <p className='text-lg font-bold uppercase'>{data.categoriaTipoMovimiento}</p>
                      <p className='text-lg font-bold uppercase'>{formatCurrency(data.montoTotal)}</p>
                    </div>
                    <img className='w-12 h-12' src={data.categoriaTipoMovimiento === 'abono' ? imgAbono : imgCargo} />
                  </div>
                </section>
                <section className='flex justify-between items-center gap-96'>
                  <div>
                    <p className='text-lg uppercase'>{data.descripcion}</p>
                    <div className='flex justify-rigt items-center gap-2'>
                      <img className='w-8 h-8' src={iconCalendario} alt='' />
                      <p className='text-lg font-bold uppercase text-center'>
                        {formatDate(data.fecha)}
                      </p>
                    </div>
                  </div>
                  <Text
                    className={
                      data.status === 'PENDIENTE'
                        ? 'text-accent3 font-bold text-xl'
                        : 'text-accent2 font-bold text-xl'
                    }
                  >
                    {data.status}
                  </Text>
                </section>
              </section>
              <section className='flex flex-col bg-gray-100 shadow-5xl px-8 py-6 rounded-2xl gap-6 mt-10'>
                <TableConciliado />
              </section>
              </>
              )}
            </div>
            <div className='flex justify-center items-center gap-3 mt-5 mr-10 mb-5 '>
              {data.status === 'PENDIENTE' ? (
                <>
                  <CustomButton title='CREAR VINCULACIÓN' color='accent2' className='rounded-full'/>
                  <CustomButton title='ASOCIAR VINCULACIÓN' color='accent2' className='rounded-full' onClick={handleOpen}/>
                  <CustomButton title='GUARDAR' color='accent6' className='rounded-full text-white'/>
                  <CustomButton onClick={handleClose} title='CERRAR' color='primary' className='rounded-full'/>
                </>
              ) : (
                <>
                  <Button
                    onClick={handleClose}
                    className='uppercase text-sm bg-primary text-white rounded-full'
                    title='cancelar'
                  />
                  <Button
                    disabled={dataCustom?.validation ? false : true}
                    onClick={toastMessageEdit}
                    className={`uppercase text-sm text-white rounded-full ${
                      dataCustom?.validation ? 'bg-accent2 ' : 'bg-[#00000030]'
                    }`}
                    title='guardar cambios'
                  />
                </>
              )}
            </div>
          </DialogContent>
        </Dialog>
      </Fragment>
      <Modal open={modalOpen} setOpen={setModalOpen} />
      {openModalCreate && (
        <ModalBancoCreateVinculacion
          open={openModalCreate}
          setOpen={setOpenModalCreate}
        />
      )}
    </div>
  )
}

export default ModalDialogMotionEditForm
