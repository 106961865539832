import React, { useState } from 'react';
import { Dialog, DialogTitle, DialogContent, IconButton, Stack, Box } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import LineChartComponent from '../BusinessChart/LineChart';
import BarChartComponent from '../BusinessChart/SimpleBarChart';
import { Skeleton } from '@mui/material';
import MultipleSelectChip from './ChipFilter'; // 🔹 Importar el filtro

interface ChartModalProps {
    open: boolean;
    onClose: () => void;
    isLoading: boolean;
    dataEgresos: any;
    dataIngresos: any;
    totalDeudasPagos: any;
}

const SkeletonGrid = ({ minHeight, length }) => (
    <Stack spacing={2} sx={{ minHeight }}>
        {Array.from({ length }).map((_, index) => (
            <Skeleton key={index} variant="rectangular" width="100%" height={50} />
        ))}
    </Stack>
);

const ChartModal: React.FC<ChartModalProps> = ({ open, onClose, isLoading, dataEgresos, dataIngresos, totalDeudasPagos }) => {
    const [selectedFilters, setSelectedFilters] = useState<string[]>([]);

    // 🔹 Verificar si los datos están disponibles y son arrays
    const safeDataEgresos = Array.isArray(dataEgresos) ? dataEgresos : [];
    const safeDataIngresos = Array.isArray(dataIngresos) ? dataIngresos : [];

    // 🔹 Determinar si se muestran los filtros (solo para gráficos de líneas)
    const showFilter = safeDataEgresos.length > 0 || safeDataIngresos.length > 0;

    // 🔹 Extraer valores únicos para el filtro
    const uniqueFilters = showFilter
        ? [
            ...new Set([
                ...safeDataEgresos.map(item => item.nombreFolio || item.nombreCuenta || item.razonSocial || item.status || item.tipoComprobante),
                ...safeDataIngresos.map(item => item.nombreFolio || item.nombreCuenta || item.razonSocial || item.status || item.tipoComprobante)
            ])
        ].filter(Boolean) // Elimina valores `undefined`
        : [];

    // 🔹 Filtrar datos según la selección del usuario
    const filterData = (data: any[]) => {
        if (!selectedFilters.length) return data;
        return data.filter(item =>
            selectedFilters.includes(item.nombreFolio) ||
            selectedFilters.includes(item.nombreCuenta) ||
            selectedFilters.includes(item.razonSocial) ||
            selectedFilters.includes(item.status) ||
            selectedFilters.includes(item.tipoComprobante)
        );
    };

    return (
        <Dialog open={open} onClose={onClose} maxWidth="lg" fullWidth>
            <DialogTitle>
                Gráfico
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>

            <DialogContent dividers>
                {/* 🔹 Mostrar filtro solo si hay datos de Egresos o Ingresos */}
                {showFilter && (
                    <MultipleSelectChip filters={uniqueFilters} selectedFilters={selectedFilters} setSelectedFilters={setSelectedFilters} />
                )}

                {/* 🔹 Contenedor de gráficos */}
                <div className="charts-container relative w-full -mt-3 mb-5 min-h-[450px]">
                    {isLoading ? (
                        <SkeletonGrid minHeight={190} length={6} />
                    ) : (
                        <Stack direction="column" spacing={2} sx={{ width: '100%' }}>
                            {/* 🔹 Mostrar gráfico de líneas SOLO para Egresos */}
                            {safeDataEgresos.length > 0 && (
                                <Box sx={{ height: '300px' }}>
                                    <LineChartComponent rawData={filterData(safeDataEgresos)} title="Egresos" />
                                </Box>
                            )}
                            {/* 🔹 Mostrar gráfico de líneas SOLO para Ingresos */}
                            {safeDataIngresos.length > 0 && (
                                <Box sx={{ height: '300px' }}>
                                    <LineChartComponent rawData={filterData(safeDataIngresos)} title="Ingresos" />
                                </Box>
                            )}
                            {/* 🔹 Mostrar gráfico de barras SOLO en Bancos (sin filtro) */}
                            {totalDeudasPagos && (
                                <Box sx={{ height: '300px' }}>
                                    <BarChartComponent rawData={totalDeudasPagos} />
                                </Box>
                            )}
                        </Stack>
                    )}
                </div>
            </DialogContent>
        </Dialog>
    );
};

export default ChartModal;