import { useState, useEffect, useContext } from 'react'
import { HeadingTitle, Paper} from '../../../components/modules/ui/index'
import {
    currentMonth,
    currentYear,
  } from '../../../components/utils/constant'
import { ExportCSV, ExportXLS } from '../../../components/modules/table/ExportData'
import ExportPDF from '../../../components/modules/table/typesExportPDF/ExportPDFEstadoResultado'
import CustomButton from '../../../components/modules/ui/CustomButton'
import Table from './Table'
import GlobalContext from '../../../context/global-context'
import { BusinessAPI } from '../../../api/business.api'
import dayjs from 'dayjs'
import FilterDateControl from '../../../components/modules/table/FilterDateControl'

const ListEstadoDeResultados = () => {
  const [monthFilter] = useState(currentMonth)
  const [yearFilter] = useState(currentYear)
  const { ui, currentBusiness } = useContext(GlobalContext)
  const businessAPI = new BusinessAPI()
  const [data, setData] = useState([])
  const [filters] = useState({
      fechaInicio: dayjs().startOf('month').format('YYYY-MM-DD'),
      fechaFin: dayjs().format('YYYY-MM-DD'),
    })
    const [tempFilters, setTempFilters] = useState({ ...filters })

  /*const monthsOptions = months.map(monthOption => ({
    ...monthOption,
    disabled: yearFilter == currentYear && monthOption.value > currentMonth
  }))*/

  const getData = async () => {
    const monthFormatter = monthFilter.toString().length === 1 ? `0${monthFilter}` : monthFilter

    if (currentBusiness.id) {
      ui.setLoader({ visible: true, text: 'Cargando los datos de la tabla...' })
      await businessAPI
        .getEstadoResultado({
          businessId: currentBusiness.id,
          month: monthFormatter,
          year: yearFilter
        })
        .then(({ data }) => {
          setData(data)
        })
        .catch(error => {
          setData([])
          console.error(error)
        })
        .finally(() => {
          ui.setLoader({ visible: false, text: '' })
        })
    } else {
      console.error('No dato')
    }
  }

  useEffect(() => {
    getData()
  }, [currentBusiness])
    
  /*const handleMonthYearChange = (e) => {
    const [year, month] = e.target.value.split('-');
    setMonthFilter(month);
    setYearFilter(year);
  }*/

  const dataToExport = data.map(item => {
      return {
        'Codigo Cuenta': item.codeCuenta,
        'Nombre de cuenta': item.nameCuenta,
        'Saldo Mes': item.saldoMes,
        'Saldo Acumulado': item.saldoAcumulado
      }
    })

    const icons = [
      <ExportCSV
        key='export-csv'
        currentBusiness={currentBusiness.name}
        rut={currentBusiness.rut}
        data={dataToExport}
        title='Reportes Estado de Resultados'
        year={yearFilter}
        month={monthFilter}
      />,
      <ExportXLS
        key='export-xls'
        currentBusiness={currentBusiness.name}
        rut={currentBusiness.rut}
        data={dataToExport}
        title='Reportes Estado de Resultados'
        year={yearFilter}
        month={monthFilter}
      />,
      <ExportPDF
        key='export-pdf'
        currentBusiness={currentBusiness.name}
        rut={currentBusiness.rut}
        data={data}
        title='Reportes Estado de Resultados'
        year={yearFilter}
        month={monthFilter}
      />
    ]

  return (
    <>
    <Paper>
        <HeadingTitle title='Estado de Resultados' rightIcons={icons} iconHeight={32}/>
        <div>
          <div className='flex gap-2 items-end'>
            <FilterDateControl
              label='Fecha Desde'
              value={tempFilters.fechaInicio}
              onChange={newValue =>
                setTempFilters({ ...tempFilters, fechaInicio: dayjs(newValue).format('YYYY-MM-DD') })
              }
            />
            <FilterDateControl
              label='Fecha Hasta'
              value={tempFilters.fechaFin}
              onChange={newValue =>
                setTempFilters({ ...tempFilters, fechaFin: dayjs(newValue).format('YYYY-MM-DD') })
              }
            />
            <CustomButton
              className='rounded-full'
              color='primary'
              title='Buscar'
              onClick={getData}
            />
          </div>
        </div>
        <Table 
          rows={data}
          getData={getData}
        />
    </Paper>
    </>
  )
}

export default ListEstadoDeResultados
