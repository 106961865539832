import { useState } from 'react'
import clsx from 'clsx'
import { Text } from '../../../components/modules/ui'
import { formatCurrency } from '../../../components/utils/formatter'

const Table = ({ rows }) => {
  const [order, setOrder] = useState({ col: 'Folio', field: 'numeroFolio', direction: 'desc' })

    const handleSort = ({ col, field }) => {
      const isAsc = order.col === col && order.direction === 'asc'
      setOrder({ col, field, direction: isAsc ? 'desc' : 'asc' })
    }

    const orderableCols = [
      { col: 'Codigo Cuenta', field: 'codigoCuenta' },
      { col: 'Nombre Cuenta', field: 'nombreCuenta' },
      { col: 'Saldo Mes', field: 'saldoMes' },
      { col: 'Saldo Acumulado', field: 'saldoAcumulado' }
    ]

    const styles = {
      table: 'w-full text-[#000231] text-xs border-collapse leading-6 table-auto', // Cambiamos a table-auto
      tr: 'hover:bg-gray-100/50',
      td: 'text-left align-top p-4 whitespace-nowrap overflow-x-auto no-scrollbar-space-y-1',
      tdCodeCuenta: 'w-[50px]', // Ajuste fijo para Codigo cuenta
      tdNameCuenta: 'flex-grow', // Flex para que Nombre cuenta ocupe el espacio sobrante
      th: 'border-2 border-transparent border-b-gray-200 text-left p-4 whitespace-nowrap bg-white',
      thead: 'sticky top-0 z-10',
      tbody: 'overflow-y-auto',
      boldText: 'font-bold',
      text: 'text-xs text-gray-500'
    }

  const searchColsIndex = col => orderableCols.find(item => item.col === col)

  if (!rows?.[0]) return <Text className='grid place-items-center py-12'>No hay datos</Text>

  return (
    <div className='overflow-x-auto'>
      <div className='max-h-96 overflow-y-auto mt-10'>
        <table className={styles.table}>
          <thead className={styles.thead}>
            <tr>
              {orderableCols.map((col, i) => (
                <th
                  className={clsx(styles.th, { 'cursor-pointer': searchColsIndex(col) })}
                  onClick={() => searchColsIndex(col) && handleSort(searchColsIndex(col))}
                  key={i}
                >
                  {col.col}
                </th>
              ))}
            </tr>
          </thead>
          <tbody className={styles.tbody}>
            {rows.map((row, i) => (
              <tr className={styles.tr} key={i}>
                {/* Codigo cuenta */}
                <td className={clsx(styles.td, styles.tdCodeCuenta)}>
                  <Text className={styles.boldText}>{row.codeCuenta}</Text>
                </td>
                {/* Nombre cuenta */}
                <td className={clsx(styles.td, styles.tdNameCuenta)}>
                  <Text className={styles.boldText}>{row.nameCuenta}</Text>
                </td>
                {/* Saldo mes */}
                <td className={styles.td}>
                  <Text className={styles.text}>{formatCurrency(row.saldoMes)}</Text>
                </td>
                {/* Saldo acumulado */}
                <td className={styles.td}>
                  <Text className={styles.text}>{formatCurrency(row.saldoAcumulado)}</Text>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  )
}

export default Table
