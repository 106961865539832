import React from 'react'
import {
    LineChart,
    Line,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    ResponsiveContainer
} from 'recharts'

// Definir tipos de datos
type RawDataItem = { fecha: string; montoTotal: number; nombreFolio: string }
type WeekGroup = Record<string, { days: string[]; firstDay: string }> // Semanas con sus días y primer día

// Función para formatear la fecha a "YYYY-MM-DD"
const formatDate = (dateString: string): string => {
    return new Date(dateString).toISOString().split('T')[0]
}

// Eliminar duplicados de los datos brutos
const removeDuplicates = (rawData: RawDataItem[]): RawDataItem[] => {
    const uniqueEntries = new Set<string>()
    return rawData.filter(item => {
        const uniqueKey = `${item.fecha}-${item.nombreFolio}-${item.montoTotal}`
        if (uniqueEntries.has(uniqueKey)) {
            return false // Es un duplicado
        }
        uniqueEntries.add(uniqueKey)
        return true
    })
}

// Agrupar días por semanas y obtener el primer día de la semana
const groupDaysByWeek = (year: number, month: number): WeekGroup => {
    const weeks: WeekGroup = {}
    const firstDayOfMonth = new Date(year, month - 1, 1) // Primer día del mes
    const lastDayOfMonth = new Date(year, month, 0) // Último día del mes

    let currentWeek = 1
    // eslint-disable-next-line prefer-const
    let currentDate = new Date(firstDayOfMonth)

    while (currentDate <= lastDayOfMonth) {
        const weekKey = `Semana ${currentWeek}`

        if (!weeks[weekKey]) {
            weeks[weekKey] = { days: [], firstDay: formatDate(currentDate.toISOString()) }
        }

        weeks[weekKey].days.push(currentDate.toISOString().split('T')[0])

        if (currentDate.getDay() === 0) {
            currentWeek++
        }

        currentDate.setDate(currentDate.getDate() + 1)
    }

    return weeks
}

// Clasificar datos por semanas
const classifyDataByWeek = (
    rawData: RawDataItem[],
    groupedWeeks: WeekGroup
): Record<string, Record<string, number>> => {
    const weeklyData: Record<string, Record<string, number>> = {}

    rawData.forEach(item => {
        const itemDate = item.fecha.split('T')[0] // Formato YYYY-MM-DD
        const weekKey = Object.keys(groupedWeeks).find(week =>
            groupedWeeks[week].days.includes(itemDate)
        )

        if (weekKey) {
            const firstDay = groupedWeeks[weekKey].firstDay // Usar el primer día como clave

            if (!weeklyData[firstDay]) {
                weeklyData[firstDay] = {}
            }

            if (!weeklyData[firstDay][item.nombreFolio]) {
                weeklyData[firstDay][item.nombreFolio] = 0
            }

            weeklyData[firstDay][item.nombreFolio] += item.montoTotal
        }
    })

    return weeklyData
}

// Transformar los datos para el gráfico
const transformToChartData = (weeklyData: Record<string, Record<string, number>>) => {
    return Object.entries(weeklyData)
        .sort(([firstDayA], [firstDayB]) => {
            // Ordenar semanas de menor a mayor usando las fechas
            return new Date(firstDayA).getTime() - new Date(firstDayB).getTime()
        })
        .map(([firstDay, folios]) => {
            const weekEntry: Record<string, any> = { semana: firstDay } // Usar el primer día como clave
            Object.entries(folios).forEach(([folio, total]) => {
                weekEntry[folio] = total
            })
            return weekEntry
        })
}

const generateColors = (count: number): string[] => {
    const colors: string[] = []; // Explicitly set the type of colors as string[]
    for (let i = 0; i < count; i++) {
        const hue: number = (i * 137.508) % 360; // Distribute hues around the color wheel
        colors.push(`hsl(${hue}, 70%, 50%)`);
    }
    return colors;
};

const LineChartComponent: React.FC<{ rawData: RawDataItem[]; title: string }> = ({ rawData, title }) => {
    const year = 2025;
    const month = 2; // January

    // Remove duplicates before processing
    const filteredData = removeDuplicates(rawData);

    const groupedWeeks = groupDaysByWeek(year, month);
    const weeklyData = classifyDataByWeek(filteredData, groupedWeeks);
    const chartData = transformToChartData(weeklyData);
    const uniqueFolios = [...new Set(filteredData.map(item => item.nombreFolio))];

    // Generate colors for each folio
    const colors = generateColors(uniqueFolios.length);

    return (
        <>
            <h3 style={{ textAlign: 'center', marginBottom: '10px', fontSize: '16px', fontWeight: 'bold' }}>
                {title}
            </h3>
            <ResponsiveContainer width="100%" height="100%">
                <LineChart data={chartData} margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis
                        dataKey="semana" // Show the first day of the week
                        tickFormatter={(value) =>
                            new Date(value).toLocaleDateString('es-ES', { day: 'numeric', month: 'short' })
                        } // Format date (e.g., "1 ene.")
                        tick={{ fontSize: 13 }}
                    />
                    <YAxis
                        scale="log"
                        domain={[1, 'dataMax']}
                        tickFormatter={(value) => value.toLocaleString()} // Mostrar valores con separadores
                        hide={true}
                    />

                    <Tooltip />
                    {uniqueFolios.map((folio, index) => (
                        <Line
                            key={folio}
                            type="monotone"
                            dataKey={folio}
                            stroke={colors[index]} // Apply the generated color
                            activeDot={{ r: 8 }}
                        />
                    ))}
                </LineChart>
            </ResponsiveContainer>
        </>
    );
};


export default LineChartComponent
