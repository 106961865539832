import { useState, useContext, useEffect } from 'react'
import { HeadingTitle, Paper, SearchInput} from '../../../components/modules/ui/index'
import {
    currentMonth,
    currentYear,
  } from '../../../components/utils/constant'
import CustomButton from '../../../components/modules/ui/CustomButton'
import Table from './Table'
import GlobalContext from '../../../context/global-context'
import { BusinessAPI } from '../../../api/business.api'
import FilterDateControl from '../../../components/modules/table/FilterDateControl'
import dayjs from 'dayjs'
import { ExportCSV, ExportXLS } from '../../../components/modules/table/ExportData'
import ExportPDF from '../../../components/modules/table/typesExportPDF/ExportPDFLibroAuxiliar'
import { formatDate } from '../../../components/utils/formatter'

const ListLibroAuxiliarCliente = () => {
    const [monthFilter] = useState(currentMonth)
    const [yearFilter] = useState(currentYear)
    const [filters] = useState({
      fechaInicio: dayjs().startOf('month').format('YYYY-MM-DD'),
      fechaFin: dayjs().format('YYYY-MM-DD'),
    })
    const [tempFilters, setTempFilters] = useState({ ...filters })
    const { ui, currentBusiness } = useContext(GlobalContext)
    const businessAPI = new BusinessAPI()
    const [data, setData] = useState([])
    const [searchQuery, setSearchQuery] = useState('')

    /*const monthsOptions = months.map(monthOption => ({
        ...monthOption,
        disabled: yearFilter == currentYear && monthOption.value > currentMonth
    }))*/

    const getData = async () => {
      const monthFormatter = monthFilter.toString().length === 1 ? `0${monthFilter}` : monthFilter
      
      if (currentBusiness.id) {
        ui.setLoader({ visible: true, text: 'Cargando los datos de la tabla...' })
        await businessAPI
          .getLibroClienteProveedor({
            businessId: currentBusiness.id,
            type: 'cliente',
            month: monthFormatter,
            year: yearFilter,
            max: 10000
          })
          .then(({ data }) => {
            setData(data)
          })
          .catch(error => {
            setData([])
            console.error(error)
          })
          .finally(() => {
            ui.setLoader({ visible: false, text: '' })
          })
      } else {
        console.error('No hay un negocio seleccionado')
      }
    }

    useEffect(() => {
      getData()
    }, [currentBusiness])

    const dataToExport = data.map(item => {
      return {
        Rut: item.rut,
        Cliente: item.razonSocial,
        Fecha: formatDate(item.fecha),
        Vencimiento: formatDate(item.fechaVencimiento),
        Comprobante: item.comprobante,
        'Tipo Documento': item.tipoComprobante,
        Documento: item.folio,
        Debe: item.debe.toLocaleString('es-CL'),
        Haber: item.haber.toLocaleString('es-CL'),
        Saldo: item.saldo.toLocaleString('es-CL')
      }
    })
      
    const icons = [
    <ExportCSV
      key='export-csv'
      currentBusiness={currentBusiness.name}
      rut={currentBusiness.rut}
      data={dataToExport}
      title='Reportes libro auxiliar de cliente'
      year={yearFilter}
    />,
    <ExportXLS
      key='export-xls'
      currentBusiness={currentBusiness.name}
      rut={currentBusiness.rut}
      data={dataToExport}
      title='Reportes libro auxiliar de cliente'
      year={yearFilter}
    />,
    <ExportPDF
      key='export-pdf'
      currentBusiness={currentBusiness.name}
      rut={currentBusiness.rut}
      data={data}
      title='Reportes libro auxiliar de cliente'
      year={yearFilter}
    />
  ]

  const filteredData = data.filter(item =>
    item.rut?.toLowerCase().includes(searchQuery.toLowerCase()) ||
    item.razonSocial?.toLowerCase().includes(searchQuery.toLowerCase())
  )

    return (
        <>
        <Paper>
            <HeadingTitle title='Libro Auxiliar de Cliente' rightIcons={icons} iconHeight={32}/>
        <div>
          <div className='flex justify-between items-end'>
            <div className='flex gap-2 items-end'>
              <FilterDateControl
                label='Fecha Desde'
                value={tempFilters.fechaInicio}
                onChange={(newValue) =>
                  setTempFilters({ ...tempFilters, fechaInicio: dayjs(newValue).format('YYYY-MM-DD') })
                }
              />
              <FilterDateControl
                label='Fecha Hasta'
                value={tempFilters.fechaFin}
                onChange={(newValue) =>
                  setTempFilters({ ...tempFilters, fechaFin: dayjs(newValue).format('YYYY-MM-DD') })
                }
              />
              <CustomButton className='rounded-full' color='primary' title='Buscar' onClick={getData} />
            </div>
            <div className='flex flex-col'>
              <SearchInput value={searchQuery} onChange={(e) => setSearchQuery(e.target.value)} />
              <label className='mt-2'>
                <input className='mr-2 rounded-sm' type='checkbox' />
                Clientes que me deben
              </label>
            </div>
          </div>
        </div>
        <Table rows={filteredData} getData={getData} />
      </Paper>
        </>
    )
    }

export default ListLibroAuxiliarCliente
