import React from 'react';
import { Theme, useTheme } from '@mui/material/styles';
import { Box, OutlinedInput, InputLabel, MenuItem, FormControl, Select, Chip } from '@mui/material';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

const getStyles = (name: string, selectedFilters: readonly string[], theme: Theme) => {
    return {
        fontWeight: selectedFilters.includes(name)
            ? theme.typography.fontWeightMedium
            : theme.typography.fontWeightRegular,
    };
};

interface MultipleSelectChipProps {
    filters: string[];
    selectedFilters: string[];
    setSelectedFilters: React.Dispatch<React.SetStateAction<string[]>>;
}

const MultipleSelectChip: React.FC<MultipleSelectChipProps> = ({ filters, selectedFilters, setSelectedFilters }) => {
    const theme = useTheme();

    const handleChange = (event: any) => {
        const { value } = event.target;
        setSelectedFilters(typeof value === 'string' ? value.split(',') : value);
    };

    return (
        <FormControl sx={{ m: 1, width: 300 }}>
            <InputLabel>Filtrar por</InputLabel>
            <Select
                multiple
                value={selectedFilters}
                onChange={handleChange}
                input={<OutlinedInput label="Filtrar por" />}
                renderValue={(selected) => (
                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                        {selected.map((value) => (
                            <Chip key={value} label={value} />
                        ))}
                    </Box>
                )}
                MenuProps={MenuProps}
            >
                {filters.map((filter) => (
                    <MenuItem key={filter} value={filter} style={getStyles(filter, selectedFilters, theme)}>
                        {filter}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
};

export default MultipleSelectChip;