import { useEffect } from 'react'
//ui
import { Paper, Title, SearchInput } from '../../../../ui/index'

//assets
import QuestionIcon from '@mui/icons-material/QuestionMarkOutlined'
import StarFullIcon from '@mui/icons-material/StarOutlined'

// hooks
import { useContentTypeReportes } from '../../../../../../pages/Reportes/useReportes.hook'

const FavoriteNavBar = () => {
  const styleImage = 'cursor-pointer w-10 h-10'
  const favoriteIcons = useContentTypeReportes(state => state.favoriteIcons)
  const removeFavoriteIcon = useContentTypeReportes(state => state.removeFavoriteIcon)
  const setFavoriteContent = useContentTypeReportes(state => state.setFavoriteContent)
  const resetTypeContent = useContentTypeReportes(state => state.resetTypeContent)

  useEffect(() => {
    resetTypeContent()
  }, [resetTypeContent])

  const handleIconClick = (icon) => {
    setFavoriteContent(icon.id)
  }

  return (
    <div>
      { favoriteIcons.length === 0 ? (
        <p></p>
      ) : (
        <Paper>
          <section className='flex items-center justify-between'>
            <Title>Favoritos</Title>
            <SearchInput placeholder='Buscar reporte' classNameBorder='rounded-full' />
          </section>
          <section className='grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 py-4 max-w-screen-lg mx-auto place-items-center'>
            {favoriteIcons.map((icon, index) => (
              <div
                key={index}
                className='flex flex-col items-center justify-center shadow-md border rounded-2xl w-52 h-52 aspect-square'
                onClick={() => handleIconClick(icon)}
              >
                <div className='flex items-center justify-between w-full px-20'>
                  <QuestionIcon className={styleImage} style={{ color: '#a1a1aa' }} />
                  <StarFullIcon
                    className={styleImage}
                    style={{ color: 'orange' }}
                    alt='icon'
                    onClick={(e) => {
                      e.stopPropagation()
                      removeFavoriteIcon(icon.id)
                    }}
                  />
                </div>
                <img className='w-20 h-20 cursor-pointer' src={icon.icon} alt={icon.title} />
                <p className='text-center text-xs'>{icon.title}</p>
              </div>
            ))}
          </section>
        </Paper>
      )}
    </div>
  )
}

export default FavoriteNavBar