import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import ChartModal from '../ChartModal/ChartModal';

interface PlusButtonWithModalProps {
    dataEgresos: any;
    dataIngresos: any;
    totalDeudasPagos: any;
    isLoading: boolean;
}

const PlusButtonWithModal: React.FC<PlusButtonWithModalProps & { chartType: 'egresos' | 'ingresos' |'bancos' }> = ({
    dataEgresos,
    dataIngresos,
    totalDeudasPagos,
    isLoading,
    chartType,
}) => {
    const [open, setOpen] = useState(false);

    return (
        <>
            <button
                onClick={() => setOpen(true)}
                className='flex items-center justify-center bg-white absolute rounded-full w-6 h-6 top-3 right-3 shadow text-slate-400/70 border border-slate-300/90'
            >
                <FontAwesomeIcon icon={faPlus} />
            </button>
            <ChartModal
                open={open}
                onClose={() => setOpen(false)}
                isLoading={isLoading}
                dataEgresos={chartType === 'egresos' ? dataEgresos : null} 
                dataIngresos={chartType === 'ingresos' ? dataIngresos : null} 
                totalDeudasPagos={chartType === 'bancos' ? totalDeudasPagos : null}
            />
        </>
    );
};

export default PlusButtonWithModal;