import clsx from 'clsx'

const Select = ({ defaultValue, options, onChange, className, disabled }) => {
  return (
    <select
      className={clsx('appearance-none focus:outline-none focus:ring-0', className, {
        'min-w-[90px] border border-gray-300 hover:border-gray-400 focus:border-gray-400 px-4 py-1 pr-6 rounded-full shadow leading-tight text-sm': 
          !className
      })}
      defaultValue={defaultValue}
      onChange={onChange}
      disabled={disabled}
    >
      {options.map(({ value, label, id, disabled }, i) => (
        <option key={id || i} value={value} disabled={disabled}>
          {label || value}
        </option>
      ))}
    </select>
  )
}


export default Select
