import clsx from 'clsx'
import { useState } from 'react'
import { Text } from '../ui'

const data = [
    {
        id: 1,
        folio: 'Factura',
        cliente: 'Inversiones',
        fecha: '22-02-2024',
        clasificacion: 'Ingresos del giro',
        monto: 'Tef bancoestado de Valenzuela',
        conciliado: '21-02-2025',
    },
    {
        id: 2,
        folio: 'Factura',
        cliente: 'Inversiones',
        fecha: '22-02-2024',
        clasificacion: 'Ingresos del giro',
        monto: 'Tef bancoestado de Valenzuela',
        conciliado: '21-02-2025',
    },
]

const TableConciliado = ({ onCheckboxChange }) => {
    const [selectedCheckboxes, setSelectedCheckboxes] = useState({})

    const handleCheckboxToggle = (id) => {
        const updatedSelection = {
          ...selectedCheckboxes,
          [id]: !selectedCheckboxes[id],
        }
        setSelectedCheckboxes(updatedSelection)
    
        const isAnySelected = Object.values(updatedSelection).some((value) => value)
        onCheckboxChange(isAnySelected)
    }

    const styles = {
        table: 'w-full text-[#000231] text-xs border-collapse leading-6 overflow-x-auto table-fixed',
        tr: 'border-b border-gray-200 hover:bg-gray-100/50',
        td: 'text-left align-top p-4 whitespace-nowrap max-w-52 overflow-x-auto no-scrollbar-space-y-1',
        th: 'border-2 border-transparent border-b-gray-200 text-left p-4 whitespace-nowrap cursor-pointer',
        boldText: 'font-bold',
        text: 'text-xs text-gray-500',
        smallCol: 'w-[45px]',
        wideCol: 'w-[300px]',
        mediumCol: 'w-[140px]',
    }

    return (
        <div className='p-4'>
            <table className={styles.table}>
                <thead className='justify-center items-center'>
                    <tr>
                    <th className={clsx(styles.th, styles.smallCol)}></th>
                    <th className={clsx(styles.th, styles.mediumCol)}>Folio</th>
                    <th className={clsx(styles.th, styles.mediumCol)}>Cliente</th>
                    <th className={clsx(styles.th, styles.wideCol)}>Fecha</th>
                    <th className={clsx(styles.th, styles.mediumCol)}>Monto</th>
                    <th className={clsx(styles.th, styles.mediumCol)}>Conciliado</th>
                    </tr>
                </thead>
                <tbody className='divide-y divide-gray-200'>
                    {data.map((item) => (
                        <tr key={item.id} className={styles.tr}>
                            <td className={clsx(styles.td, styles.smallCol)}>
                                <input type='checkbox' className='rounded' checked={!!selectedCheckboxes[item.id]} onChange={() => handleCheckboxToggle(item.id)}/>
                            </td>
                            <td className={clsx(styles.td, styles.mediumCol)}>
                                <Text className={styles.boldText}>{item.folio}</Text>
                                <Text className={styles.text}>5270</Text>
                            </td>
                            <td className={clsx(styles.td, styles.wideCol)}>
                                <Text className={styles.boldText}>{item.cliente}</Text>
                                <Text className={styles.text}>11.111.111-1</Text>
                            </td>
                            <td className={clsx(styles.td, styles.wideCol)}>
                                <Text className={styles.boldText}>{item.fecha}</Text>
                            </td>
                            <td className={clsx(styles.td, styles.wideCol)}>
                                <Text className={styles.boldText}>{item.clasificacion}</Text>
                                <Text className={styles.boldText}>10-02-2025</Text>
                            </td>
                            <td className={clsx(styles.td, styles.mediumCol)}>
                                <Text className={styles.boldText}>{item.monto}</Text>
                            </td>
                            <td className={clsx(styles.td, styles.mediumCol)}>
                                <Text className={styles.boldText}>{item.conciliado}</Text>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    )
}

export default TableConciliado
