import * as Yup from 'yup'
//assets
import tituloLoginSII from '../../../../../assets/tituloLoginSII.png'
//components
import { Form, Formik } from 'formik'
import { FieldWithErrorMessage } from '../../../../modules/ui/FieldWithErrorMsg'
import { toast } from 'react-toastify'

// utils
import { useGlobalContext } from '../../../../../context/GlobalState'
import { useMutationCreateRepresentanteLeal } from '../../../../../queries/businessQueries'

/**
 *
 * @returns {JSX.Element} The rendered component.
 */
export const AddRepresentanteLegal = () => {
  const createRepresentanteLeal = useMutationCreateRepresentanteLeal()
  const { currentBusiness } = useGlobalContext()

  const schemmaValidation = Yup.object().shape({
    user: Yup.string().required('Ingresa tu RUT'),
    password: Yup.string().required('Ingresa tu clave tributaria')
  })

  const handleSubmitForm = async (values, actions) => {
    if (!currentBusiness?.id) {
      console.error('currentBusiness or its id is undefined')
      return
    }
    try {
      // const config = { headers: { Authorization: `Bearer ${token}` } }
      // createBusiness(values)
      await toast.promise(handleCreateRepresentante(values), {
        pending: `Agregando Representante... 🚀`,
        success: `Representante agregado exitosamente 🥳`,
        error: {
          render(err) {
            const myError = err.data
            console.log(myError.message)
            return `No se pudo crear correctamente 🤯`
          }
        }
      })
      actions.resetForm()
      actions.setSubmitting(true)
    } catch (error) {
      console.error('Error al intentar insertar en la base de datos:', error)
    }
  }

  const handleCreateRepresentante = async values => {
    if (!currentBusiness?.id) {
      console.error('currentBusiness or its id is undefined')
      return
    }
    const businessId = currentBusiness.id
    try {
      const response = await createRepresentanteLeal.mutateAsync({ businessId, payload: values })
      console.info(response)
    } catch (error) {
      throw new Error(error.message)
    }
  }

  const AddCompanyForm = () => (
    <Formik
      validationSchema={schemmaValidation}
      initialValues={{ user: '', password: '' }}
      onSubmit={handleSubmitForm}
    >
      {({ isSubmitting }) => (
        <Form>
          <div className='shadow-md my-3 rounded-b-md'>
            <div className='mb-2  flex justify-center bg-primary h-[74px] py-3'>
              <img src={tituloLoginSII} alt='tituloLoginSII' />
            </div>

            <ul className='flex flex-col gap-3 px-6 pb-6'>
              <li>
                <FieldWithErrorMessage
                  type='text'
                  name='user'
                  label='RUT'
                  placeholder='Ej: 123456789'
                />
              </li>
              <li>
                <FieldWithErrorMessage
                  type='password'
                  name='password'
                  label='Ingresar Clave Tributaria'
                  placeholder='Ingresa tu clave tributaria'
                />
              </li>
              <li>
                <button disabled={isSubmitting} type='submit' className='btn-primary text-sm'>
                  AGREGAR REPRESENTANTE
                </button>
              </li>
            </ul>
          </div>
        </Form>
      )}
    </Formik>
  )

  return (
    <section className='py-2'>
      <h3 className='font-bold text-xl mb-4 text-center'>Agregar Representante Legal</h3>

      <div className='grid grid-cols-2 pl-1'>
        <AddCompanyForm />
      </div>
    </section>
  )
}
