import { useContext, useEffect, useState } from 'react'
import GlobalContext from '../../../context/global-context'

// MUI
import { Backdrop } from '@mui/material'

//api
import { TablesAPI } from '../../../api/table.api'
import { BusinessAPI } from '../../../api/business.api'

// components
import TableBalance from './Table'
import { ExportCSV, ExportXLS } from '../../../components/modules/table/ExportData'
import ExportPDF from '../../../components/modules/table/typesExportPDF/ExportPDFBalanceGeneral'

//ui
import { HeadingTitle, Paper, Text, InputCheckBox } from '../../../components/modules/ui/index'
import CustomButton from '../../../components/modules/ui/CustomButton'
import FilterDateControl from '../../../components/modules/table/FilterDateControl'
import dayjs from 'dayjs'

const ListBalanceGeneral = () => {
  const [showModal, setShowModal] = useState(false)
  const [data, setData] = useState([])
  const [acounts, setAcounts] = useState([])
  const { ui, currentBusiness } = useContext(GlobalContext)
  const [filters, setFilters] = useState({
    fechaInicio: dayjs().startOf('month').format('YYYY-MM-DD'),
    fechaFin: dayjs().format('YYYY-MM-DD'),
  })
  const [tempFilters, setTempFilters] = useState({ ...filters })
  const [checked, setChecked] = useState(false)
  const tablesAPI = new TablesAPI()
  const businessAPI = new BusinessAPI()

  const getData = async updatedFilters => {
    if (currentBusiness.id) {
      ui.setLoader({ visible: true, text: 'Cargando los datos de la tabla...' })

      try {
        const { fechaInicio, fechaFin } = updatedFilters
        const [balanceData, acountsData] = await Promise.all([
          tablesAPI.getDataBalance(
            currentBusiness.id,
            fechaInicio,
            fechaFin
          ),
          businessAPI.getCuentasUsadas({
            businessId: currentBusiness.id
          })
        ])

        setData(balanceData.data)
        setAcounts(acountsData.data)
      } catch (error) {
        setData([])
        setAcounts([])
        console.error(error)
      } finally {
        ui.setLoader({ visible: false, text: '' })
      }
    } else {
      console.error('No hay un negocio seleccionado')
    }
  }

  const Modal = () => {
    const { show, title, body } = showModal
    if (!show) return null
    return (
      <Backdrop sx={{ zIndex: 1600 }} open={true}>
        <div className='py-6 px-12 flex flex-col items-center justify-center bg-white'>
          <Text variant='h6' className='text-xl mb-4 text-center'>
            {title}
          </Text>
          {body}
          <button
            className='bg-primary text-white rounded-full px-4 py-2 hover:bg-primary/80 focus:outline-none shadow'
            onClick={() => setShowModal(false)}
          >
            Cerrar
          </button>
        </div>
      </Backdrop>
    )
  }

  const handleCheckBoxChange = () => {
    // Añadido
    setChecked(!checked)
    if (!checked) {
      const updatedData = []

      // Crear un nuevo array basado en las coincidencias
      data.forEach(item => {
        const match = acounts.find(acc => acc.codigoCuenta === item.numCuenta)
        if (match) {
          updatedData.push(item)
        }
      })

      // Añadir cuentas sin coincidencia
      acounts.forEach(acc => {
        const match = data.find(item => item.numCuenta === acc.codigoCuenta)
        if (!match) {
          updatedData.push({
            debe: 0,
            haber: 0,
            nameCuenta: acc.nombreCuenta,
            numCuenta: acc.codigoCuenta,
            acreedor: 0,
            deudor: 0,
            activo: 0,
            pasivo: 0,
            perdida: 0,
            ganancia: 0
          })
        }
      })

      // Agregar los últimos 3 elementos originales de data
      const lastThreeItems = data.slice(-3)
      updatedData.push(...lastThreeItems)

      setData(updatedData)
    } else {
      getData() // Restablecer los datos originales si se deselecciona el checkbox
    }
  }

  const dataToExport = data.map(item => {
    return {
      'N° Cuenta': item.numCuenta,
      'Nombre Cuenta': item.nameCuenta,
      Debe: item.debe.toLocaleString('es-CL'),
      Haber: item.haber.toLocaleString('es-CL'),
      Deudor: item.deudor.toLocaleString('es-CL'),
      Acreedor: item.acreedor.toLocaleString('es-CL'),
      Activo: item.activo.toLocaleString('es-CL'),
      Pasivo: item.pasivo.toLocaleString('es-CL'),
      Perdida: item.perdida.toLocaleString('es-CL'),
      Ganancia: item.ganancia.toLocaleString('es-CL')
    }
  })
  const yearFilter = dayjs().year()
  const icons = [
    <ExportCSV
      key='export-csv'
      currentBusiness={currentBusiness.name}
      rut={currentBusiness.rut}
      data={dataToExport}
      title='Reportes Balance General'
      year={yearFilter}
    />,
    <ExportXLS
      key='export-xls'
      currentBusiness={currentBusiness.name}
      rut={currentBusiness.rut}
      data={dataToExport}
      title='Reportes Balance General'
      year={yearFilter}
    />,
    <ExportPDF
      key='export-pdf'
      currentBusiness={currentBusiness.name}
      rut={currentBusiness.rut}
      data={data}
      setShowModal={setShowModal}
      title='Reportes Balance General'
      year={yearFilter}
    />
  ]

  useEffect(() => {
    getData(filters)
  }, [currentBusiness])

  const handleSearch = () => {
    setFilters({ ...tempFilters })
    getData(tempFilters)
  }

  return (
    <>
      <Paper>
        <HeadingTitle title='Balance General' rightIcons={icons} iconHeight={32} />
        <div>
        <div className='flex gap-2 items-end'>
          <FilterDateControl
            label='Fecha Desde'
            value={tempFilters.fechaInicio}
            onChange={newValue =>
              setTempFilters({ ...tempFilters, fechaInicio: dayjs(newValue).format('YYYY-MM-DD') })
            }
          />
          <FilterDateControl
            label='Fecha Hasta'
            value={tempFilters.fechaFin}
            onChange={newValue =>
              setTempFilters({ ...tempFilters, fechaFin: dayjs(newValue).format('YYYY-MM-DD') })
            }
          />
              {/*<label className='text-sm mb-1'>Mes</label>
              <Select
                  options={yearsOptions.filter((year) => year.value === 2024)}
                  defaultValue={yearFilter}
                  onChange={(e) => setYearFilter(e.target.value)}
                />*/}
           {/* <div className='flex flex-col'>
              <label className='text-sm mb-1'>Centro de Costo</label>
              <Select label='Centro de Costo' value={''} options={[]} disabled />
            </div>
            <div className='flex flex-col'>
              <label className='text-sm mb-1'>Sucursal</label>
              <Select label='Sucursal' value={''} options={[]} disabled />
            </div>*/}
            <InputCheckBox
              label='Mostrar todas las cuentas'
              id='allAconuts'
              value='allAconuts'
              name='allAconuts'
              checked={checked}
              onChange={handleCheckBoxChange}
            />
            <CustomButton
              className='rounded-full'
              color='primary'
              title='Buscar'
              onClick={handleSearch}
            />
          </div>
        </div>
        <TableBalance rows={data} getData={getData} />
        <Modal onClose={() => setShowModal(false)} />
      </Paper>
    </>
  )
}

export default ListBalanceGeneral
