import { Link, useLocation } from 'react-router-dom'
import { menuItemsDefaults } from './data.js'
import { ReactComponent as LogoLuca } from '../../../assets/logoLuca.svg'
import { ReactComponent as IsoLogo } from '../../../assets/isoLogo1.svg'
import { Field, Form, Formik } from 'formik'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLightbulb } from '@fortawesome/free-solid-svg-icons'
import { useGlobalContext } from '../../../context/GlobalState'
import { toast } from 'react-toastify'
import { BaseHTTP } from '../../../api/base.http'
import { KeyboardArrowLeftOutlined, KeyboardArrowRightOutlined, PushPinOutlined as PushPinOutlinedIcon } from '@mui/icons-material'
//import useSidebar from './useSideBar.hook.js'
import { useSidebarContext } from './SidebarContext.js'

const Sidebar = () => {
  const { currentBusiness } = useGlobalContext()
  const location = useLocation()
  const currentPath = location.pathname
  const { isExpanded, isPinned, toggleSidebar, togglePinSidebar } = useSidebarContext()
 
  const NavLinks = () => (
    menuItemsDefaults.map((menuItem) => (
      <Link
        key={menuItem.id}
        to={menuItem.path}
        className={`
          flex items-center gap-4 mb-4 py-1 px-3 rounded-full transition-all relative 
          ${currentPath === menuItem.path ? 'bg-white text-primary shadow-lg' : 'text-white'}
           ${currentPath !== menuItem.path ? 'hover:bg-white/35 hover:scale-110 active:scale-100' : ''}
          ${!isExpanded ? 'p-4 w-12 h-12' : 'px-4 py-2'}
        `}
      >
        <div className={`flex justify-center items-center w-10 h-10 rounded-full transition-all ${currentPath === menuItem.path ? '' : 'bg-transparent'}`}>
          <menuItem.icon className='w-6 h-6' alt={menuItem.name} />
        </div>
        {isExpanded && <p className='text-sm font-bold'>{menuItem.name}</p>}
      </Link>
    ))
  )

  const ContactSection = () => {
    return (
      <div className='flex flex-col gap-6 text-white/70'>
        <Formik
          initialValues={{ message: '' }}
          onSubmit={async (values, actions) => {
            await toast.promise(
              BaseHTTP.getInstance().http.post(
                'https://formsubmit.co/ajax/symmtec@icloud.com',
                {
                  from: currentBusiness.email,
                  organizationId: currentBusiness.id,
                  bussinesName: currentBusiness.name,
                  bussinesRut: currentBusiness.rut,
                  bussinesPhone: currentBusiness.phone,
                  message: values.message
                }
              ),
              {
                pending: 'Enviando mensaje...',
                success: 'Mensaje enviado con éxito',
                error: 'Ocurrió un error al enviar el mensaje'
              }
            )
            actions.setSubmitting(false)
            actions.resetForm()
          }
          } >
          <Form className='w-full h-full'>
            <ul className='w-full flex flex-col gap-3'>
              <li className='flex gap-2 items-center'>
                <FontAwesomeIcon icon={faLightbulb} size='xl' />
                <h4 className='font-semibold'>¿Te gustaría sugerirnos algo?</h4>
              </li>

              <p className='text-sm'>
                Escribenos tu idea, sugerencia o cambio y con el
                equipo de desarrolladores lo haremos para tí.
              </p>

              <Field
                className='w-full bg-white/30 rounded-lg'
                as='textarea'
                placeholder='Escribe aquí lo que te gustaría...'
                name='message'
              />

              <button type='submit' className='rounded-full ml-auto w-fit btn-light text-black py-1 font-extrabold text-sm'>Enviar</button>
            </ul>

          </Form>
        </Formik>

        <div className='mt-4 border-t border-white/80 w-1/2 py-2'>Lucapp.ai &copy</div>
      </div>
    )
  }

  return (
    <div className={`transition-all duration-300 sticky top-0 h-screen flex flex-col justify-between items-stretch ${isExpanded ? 'w-64' : 'w-20'} bg-primary text-secondary p-2 z-30`}>
      <div className='flex justify-between mb-2'>
        <button onClick={toggleSidebar} className='text-white hover:text-gray-400'>
          {isExpanded ? <KeyboardArrowLeftOutlined /> : <KeyboardArrowRightOutlined />}
        </button>

        {isExpanded && (
          <button
            onClick={togglePinSidebar}
            className={`ml-2 ${isPinned ? 'text-accent2' : 'text-white'} hover:text-gray-400`}
          >
            <PushPinOutlinedIcon />
          </button>
        )}
      </div>

      <div className='flex items-center justify-center mb-12'>
        <Link to='/'>
          <IsoLogo className='text-secondary min-w-10 h-12 max-h-14 cursor-pointer' alt='Isologo' />
        </Link>
        {isExpanded && (
          <LogoLuca className='text-secondary ml-2 w-24' alt='Logo' />
        )}
      </div>

      <NavLinks />

      {isExpanded && (
        <div className='mt-auto text-white/70 gap-4'>
          <ContactSection />
        </div>
      )}
    </div>
  )
}

export default Sidebar
