import { useContext, useState, useEffect } from "react";
import { PlanCuentasApi } from "../../../../../../../api/planCuentas.api";
import SearchInput from "../../../../../ui/SearchInput.js";
import FolderItem, { structureFolders } from "./Folders/FolderItem";
import { PDFDownloadLink } from "@react-pdf/renderer";
import { generateExcel, PlanDeCuentasPDF } from "../Utils/ExportUtilsPlanDeCuentas.js";
import translations from "../../../../../ui/traslation/translationPLanDeCuentas.json";
import Switch from "@mui/material/Switch";
import GlobalContext from "../../../../../../../context/global-context";

const PlanDeCuentas = () => {
  const { currentBusiness } = useContext(GlobalContext);
  const [folders, setFolders] = useState([]);
  const [isEditMode, setIsEditMode] = useState(false);
  const [showDeleted, setShowDeleted] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const planCuentasApi = new PlanCuentasApi();

  // Función para obtener todos los códigos padres
  const getAllParentCodes = (codigoFinal) => {
    if (!codigoFinal || typeof codigoFinal !== "string") return []; // Validación de seguridad
  
    const codes = [];
    const parts = codigoFinal.split("-").filter(p => p !== "0");
  
    for (let i = parts.length; i > 0; i--) {
      const remainingZeros = Math.max(4 - i, 0); // Evita valores negativos en repeat
      const parentCode = parts.slice(0, i).join("-") + "-0".repeat(remainingZeros);
      codes.push(parentCode);
    }
  
    return codes;
  };

  // Aplanar carpetas
  const flattenFolders = (folders) => {
    let result = [];
    folders.forEach(folder => {
      result.push(folder);
      if (folder.subfolders?.length > 0) {
        result = result.concat(flattenFolders(folder.subfolders));
      }
    });
    return result;
  };

  // Filtrar por término
  const filterFoldersBySearchTerm = (folders, term) => {
    if (!term) return folders;
    return folders.filter(folder => {
      const nameMatch = folder.nameFinal?.toLowerCase().includes(term.toLowerCase());
      const codeMatch = folder.codigoFinal?.toLowerCase().includes(term.toLowerCase());
      return nameMatch || codeMatch;
    });
  };

  const handleToggleEditMode = () => setIsEditMode(!isEditMode);
  const handleToggleShowDeleted = () => setShowDeleted(!showDeleted);

  const refreshPlanCuentas = async () => {
    if (currentBusiness) {
      try {
        const data = await planCuentasApi.getPlanCuentasByBusinessId(currentBusiness.id);
        setFolders(structureFolders(data, showDeleted));
      } catch (error) {
        console.error(translations.errors.fetchPlanError, error);
      }
    }
  };

  useEffect(() => {
    refreshPlanCuentas();
  }, [currentBusiness, showDeleted]);

  // Procesamiento de datos
  const allFolders = flattenFolders(folders);
  const filteredFolders = allFolders.filter(folder => 
    showDeleted || folder.isDeleted !== "1"
  );
  const filteredBySearch = filterFoldersBySearchTerm(filteredFolders, searchTerm);

  // Incluir padres de los resultados
  const requiredCodes = new Set();
  filteredBySearch.forEach(folder => {
    getAllParentCodes(folder.codigoFinal).forEach(code => requiredCodes.add(code));
  });

  const finalData = allFolders.filter(folder => 
    requiredCodes.has(folder.codigoFinal) || filteredBySearch.includes(folder)
  );

  const finalFolders = structureFolders(finalData, showDeleted);
  const pdfData = flattenFolders(finalFolders);

  return (
    <div className="relative">
      {/* Botones de exportación */}
      <div className="absolute top-[-60px] right-0 z-20 flex space-x-4">
        <button
          onClick={() => generateExcel(pdfData)}
          className="w-10 h-10 font-bold text-green-700 cursor-pointer rounded-full bg-white shadow hover:shadow-md"
        >
          {translations.actions.downloadExcel}
        </button>
        <PDFDownloadLink
          document={<PlanDeCuentasPDF data={pdfData} />}
          fileName="plan_de_cuentas.pdf"
          className="w-10 h-10 font-bold text-red-700 cursor-pointer rounded-full bg-white shadow flex items-center justify-center hover:shadow-md"
        >
          {translations.actions.downloadPDF}
        </PDFDownloadLink>
      </div>

      {/* Barra de acciones */}
      <div className="flex justify-between items-center mb-4">
        <SearchInput
          placeholder={translations.actions.search}
          classNameBorder="rounded-full"
          onChange={(e) => setSearchTerm(e.target.value)}
          onKeyPress={(e) => e.key === "Enter" && setSearchTerm(e.target.value)}
        />
        <div className="flex space-x-2">
          <div className="flex items-center space-x-4">
            <span className="text-sm font-medium">{translations.actions.edit}Editar</span>
            <Switch checked={isEditMode} onChange={handleToggleEditMode} color="primary" />
          </div>
          <div className="flex items-center space-x-4">
            <span className="text-sm font-medium">{translations.actions.restore}Mostrar eliminados</span>
            <Switch
              checked={showDeleted}
              onChange={handleToggleShowDeleted}
              disabled={!isEditMode}
              color="secondary"
            />
          </div>
        </div>
      </div>

      {/* Renderizado */}
      {finalFolders.length > 0 ? (
        <div className="space-y-4">
          {finalFolders.map((folder) => (
            <FolderItem
              key={folder.cuentaContableId}
              folder={folder}
              isEditMode={isEditMode}
              businessId={currentBusiness?.id}
              refreshData={refreshPlanCuentas}
              showDeleted={showDeleted}
            />
          ))}
        </div>
      ) : (
        <p>{translations.actions.noData}</p>
      )}
    </div>
  );
};

export default PlanDeCuentas;