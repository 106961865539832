import Business from '../assets/carusel/Business.png'
import Admin from '../assets/carusel/Adminis.png'
import Reportes from '../assets/carusel/Reportes.png'
import MiContador from '../assets/carusel/MiContador.png'
import Ajustes from '../assets/carusel/Ajustes.png'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/css'
import 'swiper/css/pagination'
import { Pagination } from 'swiper/modules'

const Welcome = () => {
  const images = [
    { src: Business, alt: 'Business' },
    { src: Admin, alt: 'Admin' },
    { src: Reportes, alt: 'Reportes' },
    { src: MiContador, alt: 'Mi Contador' },
    { src: Ajustes, alt: 'Ajustes' },
  ]

  return (
    <div className='w-full max-w-lg mx-auto p-4 bg-gradient-to-r from-blue-50 to-white rounded-2xl shadow-xl'>
      <Swiper
        modules={[Pagination]}
        pagination={{ clickable: true }}
        spaceBetween={30}
        slidesPerView={1}
        className='rounded-2xl'
      >
        {images.map((image, index) => (
          <SwiperSlide key={index}>
            <div className='flex items-center justify-center bg-white rounded-2xl shadow-lg overflow-hidden'>
              <img
                src={image.src}
                alt={image.alt}
                className='w-full h-auto object-cover'
              />
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  )
}

export default Welcome
