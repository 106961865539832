import { AxiosInstance } from 'axios'
import { BaseHTTP } from './base.http'
import { PayloadLinkIntentObject, LinkIntentObject, ObjectLinkToken, Movement, SumaryMovement } from '../models/Bank';
import { TotalDeudasPagos } from '../models/Invoices';

export class BankAPI {

  private http !: AxiosInstance

  constructor() {
    this.http = BaseHTTP.getInstance().http
  }

  getWidgetToken(payload: PayloadLinkIntentObject) {
    const path = '/v1/business/bank/intent-object-link'
    return this.http.post<LinkIntentObject>(path, payload)
  }

  sendExchangeTokenToBackend(exchangeToken: string, businessId: number) {
    const path = `/v1/business/bank/link-token?exchangeToken=${exchangeToken}&businessId=${businessId}`
    return this.http.get<ObjectLinkToken>(path)
  }

  getListMovements(accountId: string, linkToken: string, id: number, idSql: number, idIsNew:string) {
    const path = `/v1/business/bank/${id}/${idSql}/movements-single?linkToken=${linkToken}&accountId=${accountId}&idIsNew=${idIsNew}`
    return this.http.get<Movement[]>(path)
  }

  postBank(payload, id) {
    const path = `v1/business/${id}/bank-account`
    return this.http.post(path, payload)
  }

  // postAcount(payload, id, idAccount) {
  //   const path = `v1/producto-bancario/business/${id}/bank-account/${idAccount}/products`
  //   return this.http.post(path, payload)
  // }

  // getTotalDeudasPagos(id) {
  //   const path = `v1/business/${id}/pagar-cobrar`
  //   return this.http.get(path)
  // }

  getListBank(id) {
    const path = `v1/business/${id}/bank-account`
    return this.http.get(path)
  }

  getListAcountBank(id) {
    const path = `v1/producto-bancario/business/${id}`
    return this.http.get(path)
  }

  getDataLibros(businessId, comprobanteId) {
    const path = `v1/business/${businessId}/libros-contables/${comprobanteId}`
    return this.http.get(path)
  }

  /**
   * Obtiene el total de deudas y pagos de la empresa actual.
   * @param businessId ID de la empresa para filtrar los datos.
   * @returns Una promesa con los datos de deudas y pagos.
   */
  getTotalDeudasPagos(businessId: string) {
    const path = `/v1/business/${businessId}/pagar-cobrar`; // Ruta con parámetro de negocio
    return this.http.get<TotalDeudasPagos>(path); // Solicitud GET con respuesta tipada
  }

  /**
   * Obtiene el resumen de movimientos de una cuenta bancaria específica o de todas.
   * 
   * @param productId - ID del producto bancario (si es 0, no se filtra por producto).
   * @param businessId - ID del negocio.
   * @param limit - Límite de registros a recuperar.
   * @param year - Año de los movimientos.
   * @param month - Mes de los movimientos.
   * @returns Lista de movimientos resumidos.
   */
  async getTransactionsSummary(
    productId: number,
    businessId: number,
    limit: number,
    year: number,
    month: number
  ): Promise<SumaryMovement[]> {
    // Construcción dinámica de la URL
    const path = `v1/business/${businessId}/producto-bancario/${productId}/summary-movements?month=${month}&year=${year}&limit=${limit}`;
    try {
      // Realizar solicitud GET
      const response = await this.http.get<SumaryMovement[]>(path);
      return response.data;
    } catch (error: any) {
      console.error(
        `Error al obtener movimientos (productoId=${productId}, businessId=${businessId}):`,
        error.response?.data || error.message
      );
      throw error;
    }
  }

}
