import { Form, Formik } from 'formik'
import { useLucaSignUp } from '../hooks/useLucaSignUp'
import * as Yup from 'yup'
import { useSwiper } from 'swiper/react'
import { faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import HeelpIcon from '@mui/icons-material/HelpOutlineOutlined'

const step3ValidationSchema = Yup.object({
  userType: Yup.string().required('Selecciona un tipo de usuario')
})

/**
 *
 * @returns {JSX.Element} The rendered component.
 */

const HelpIcon = () => {
  return (
    <div className='relative group'>
      <HeelpIcon />
      <div className='absolute hidden group-hover:flex items-center justify-center bg-primary text-white text-sm rounded-lg py-2 px-3 top-full mt-2 right-0 w-64 max-w-none z-50'>
        Elige el tipo de rol que mejor te reptesenta. Puede ser un contador que gestiona multiples clientes o un socio.
      </div>
    </div>
  )
}

export function StepForm3() {
  const addAccountProfileValues = useLucaSignUp(state => state.setAccountProfile)
  const accountProfile = useLucaSignUp(state => state.accountProfile)
  const swiper = useSwiper()

  const step3Values = {
    userType: accountProfile?.userType ?? ''
  }

  const handleNext = (values, actions) => {
    addAccountProfileValues(values)
    swiper.slideNext()
    actions.setSubmitting(true)
  }

  return (
    <Formik
      validationSchema={step3ValidationSchema}
      initialValues={step3Values}
      onSubmit={handleNext}
    >
      {({ setFieldValue, values }) => (
        <Form className='relative px-1 pt-8'>
          <div className='absolute top-0 right-2'>
            <HelpIcon />
          </div>
          <div className='relative'><h3 className='mb-8 text-2xl font-bold text-center'>¿Qué tipo de rol te define mejor?</h3></div>
          <div className='grid grid-cols-1 gap-x-8 gap-y-3 mt-6'>
            <button
              type='button'
              onClick={() => setFieldValue('userType', 'contador')}
              className={`btn-light-alt rounded-full w-3/4 mx-auto py-2 ${values?.userType === 'contador' && 'bg-primary/20 text-slate-600 focus:ring-primary/10 hover:bg-primary/30'}`}
            >
              Contador/Empresa Contable
            </button>
            <button
              type='button'
              onClick={() => setFieldValue('userType', 'empresa')}
              className={`btn-light-alt rounded-full w-3/4 mx-auto py-2 ${values?.userType === 'empresa' && 'bg-primary/20 text-slate-600 focus:ring-primary/10 hover:bg-primary/30'}`}
            >
              Socio/Empresa
            </button>
          </div>

          {/* Botones de navegación más abajo */}
          <div className='flex flex-col gap-2 mt-12 w-2/3 mx-auto'>
            <div className='flex items-center justify-center gap-8'>
              <button onClick={() => swiper.slidePrev()} type='button' className='btn-light-alt rounded-full'>
                <FontAwesomeIcon size='lg' icon={faArrowLeft} />
                <span>Volver</span>
              </button>
              <button type='submit' className='btn-primary rounded-full'>
                <span>Continuar</span>
                <FontAwesomeIcon size='lg' icon={faArrowRight} />
              </button>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  )
}
