import Autocomplete from '@mui/material/Autocomplete'
import TextField from '@mui/material/TextField'
import { styled, lighten, darken } from '@mui/system'
import { useEffect, useMemo, useCallback } from 'react'

const AutoCompleteHeader = ({ currentBusiness, businessData, onClick }) => {
  const GroupHeader = styled('div')(({ theme }) => ({
    position: 'sticky',
    top: '-8px',
    padding: '4px 10px',
    color: theme.palette.primary.main,
    backgroundColor:
      theme.palette.mode === 'light'
        ? lighten(theme.palette.primary.light, 0.85)
        : darken(theme.palette.primary.main, 0.8)
  }))

  const options = useMemo(() => businessData?.map(option => ({
    name: option.name,
    firstLetter: option.name?.[0]?.toUpperCase(),
    ...option
  })), [businessData])

  const handleClick = useCallback((newValue) => {
    onClick(newValue || 'ingresa una búsqueda ')
  }, [onClick])

  useEffect(() => {
    if (!businessData?.length || !currentBusiness) return

    const exists = businessData.some((b) => b.name === currentBusiness.name)
    if (!exists) {
      onClick(businessData[0])
    }
  }, [businessData, currentBusiness])

  return (
    <Autocomplete
      id='grouped-demo'
      options={options?.sort((a, b) => -b.firstLetter.localeCompare(a.firstLetter))}
      groupBy={(option) => option.firstLetter}
      getOptionLabel={(option) => option.name}
      value={currentBusiness}
      onChange={(event, newValue) => handleClick(newValue)}
      className='rounded-full'
      sx={{
        width: 350,
        fontSize: 15,
        '.MuiOutlinedInput-root': { borderRadius: '9999px', padding: '10px 14px' }
      }}
      renderInput={(params) => (
        <TextField {...params} label='Empresas' InputProps={{ ...params.InputProps, className: 'rounded-full' }} />
      )}
      renderGroup={(params) => (
        <li key={params.key}>
          <GroupHeader sx={{ fontSize: 15 }}>{params.group}</GroupHeader>
          <ul className='p-0 text-sm'>{params.children}</ul>
        </li>
      )}
      defaultValue={currentBusiness}
      clearIcon={null}
    />
  )
}
export default AutoCompleteHeader
