import * as XLSX from 'xlsx'
import { CSVLink } from 'react-csv'
// import { PDFDownloadLink, Document, Page, Text } from '@react-pdf/renderer'

const ExportXLS = ({ data, title, year, month, currentBusiness, rut }) => {
  const exportToXLS = () => {
    const ws = XLSX.utils.json_to_sheet([])
    const boldStyle = { font: { bold: true } }
    const centerStyle = { alignment: { horizontal: 'center' } }

    ws['A1'] = { v: 'Resumen de movimientos', s: { font: { bold: true } } }
    ws['A2'] = { v: currentBusiness, s: { font: { bold: true } } }
    ws['A3'] = { v: rut, s: { font: { bold: true } } }
    ws['!ref'] = 'A1:A4'

    ws['D4'] = { v: title, s: { ...boldStyle, ...centerStyle } }
    ws['D5'] = { v: `EJERCICIO DESDE ${month} A ${month} DE ${year}`, s: centerStyle }

     const lastDataRow = data.length + 6
     const lastRow = lastDataRow + 5
     ws[`B${lastRow}`] = {
       v: 'Declaro(mos) dejando constancia que el presente Balance General ha sido confeccionado correctamente. Para constancia, firmamos:',
       s: centerStyle,
     }
     ws[`B${lastRow + 3}`] = { v: '__________________', s: centerStyle }
     ws[`D${lastRow + 3}`] = { v: '__________________', s: centerStyle }
     ws[`B${lastRow + 4}`] = { v: 'Contador', s: centerStyle }
     ws[`D${lastRow + 4}`] = { v: 'Representante Legal', s: centerStyle }
     ws['!ref'] = `A1:Z${lastRow + 5}`
     const wb = XLSX.utils.book_new()
     XLSX.utils.book_append_sheet(wb, ws, 'Sheet1')
     XLSX.writeFile(wb, `movimientos_${title}_${currentBusiness}_${rut}_${month}_${year}.xlsx`)
   }
 
   return (
     <button
       className='w-10 h-10 text-green-700 font-bold tracking-tighter cursor-pointer rounded-full shadow-full-sm bg-white'
       onClick={exportToXLS}
     >
       XLS
     </button>
   )
 }

const ExportCSV = ({ data, title, year, month, currentBusiness, rut }) => {
  return (
    <CSVLink
      data={data}
      filename={`movimientos_${title}_${currentBusiness}_${rut}_${month}_${year}.csv`}
    >
      <button className='w-10 h-10 text-blue-700 font-bold tracking-tighter cursor-pointer rounded-full shadow-full-sm bg-white'>
        CSV
      </button>
    </CSVLink>
  )
}

export { ExportXLS, ExportCSV }
