import * as XLSX from "xlsx";
import { Document, Page, Text, View, StyleSheet } from "@react-pdf/renderer";
import translations from "../../../../../ui/traslation/translationPLanDeCuentas.json"


// Estilo para el PDF
const styles = StyleSheet.create({
  page: { padding: 20 },
  table: { display: "table", width: "auto", borderStyle: "solid", borderWidth: 1 },
  tableRow: { flexDirection: "row" },
  tableCol: { width: "50%", borderStyle: "solid", borderWidth: 1, padding: 5 },
  tableCell: { textAlign: "center", fontSize: 10 },
  header: { marginBottom: 10, fontSize: 14, textAlign: "center", fontWeight: "bold" },
});

/**
 * Genera un archivo Excel desde los datos jerárquicos.
 * @param {Array} folders - Datos jerárquicos.
 */
export const generateExcel = (folders) => {
  const rows = convertToExcelFormat(folders);
  const ws = XLSX.utils.json_to_sheet(rows);
  const wb = XLSX.utils.book_new();
  const sheetName = translations.planDeCuentas.excel.sheetName; // Traducción para el nombre de la hoja
  const fileName = translations.planDeCuentas.excel.fileName; // Traducción para el nombre del archivo

  XLSX.utils.book_append_sheet(wb, ws, sheetName);
  XLSX.writeFile(wb, fileName); // Descarga con el nombre traducido
};

/**
 * Convierte los datos jerárquicos en formato plano para Excel.
 * @param {Array} folders - Datos jerárquicos.
 * @returns {Array} Datos en formato plano.
 */
const convertToExcelFormat = (folders) => {
  const columns = translations.planDeCuentas.excel.columns;
  const rows = [];

  const recursiveProcess = (folder) => {
    if (!folder) return;

    const codigoFinal = folder.codigoFinal || "0-0-0-0";
    const codigoParts = codigoFinal.split("-");
    
    // Manejar códigos con más de 4 segmentos
    const maxLength = 4;
    const slicedParts = codigoParts.slice(0, maxLength); // Cortar a máximo 4 elementos
    const fillLength = Math.max(maxLength - slicedParts.length, 0); // Evitar valores negativos
    
    const filledParts = [
      ...slicedParts,
      ...Array(fillLength).fill("")
    ];

    const [nivel1, nivel2, nivel3, nivel4] = filledParts;

    rows.push({
      [columns.level1]: nivel1,
      [columns.level2]: nivel2,
      [columns.level3]: nivel3,
      [columns.level4]: nivel4,
      [columns.accountCode]: codigoFinal,
      [columns.description]: folder.nameFinal || "",
    });

    folder.subfolders?.forEach(recursiveProcess);
  };

  folders.forEach((folder) => recursiveProcess(folder));
  return rows;
};

/**
 * Componente para generar el PDF con los datos del plan de cuentas.
 * @param {Array} data - Datos del plan de cuentas.
 * @returns {JSX.Element} Componente PDF.
 */
export const PlanDeCuentasPDF = ({ data }) => {
  const columns = translations.planDeCuentas.pdf.columns;
  const noDataMessage = translations.planDeCuentas.pdf.noData;
  const title = translations.planDeCuentas.pdf.title;

  if (!data || data.length === 0) {
    return <Text>{noDataMessage}</Text>;
  }

  const flatData = [];

  const flattenData = (folder) => {
    if (!folder) return; // Validación de folder undefined

    flatData.push({
      [columns.accountCode]: folder.codigoFinal || "", // Propiedad corregida
      [columns.description]: folder.nameFinal || "", // Propiedad corregida
    });

    // Optional chaining para subfolders
    folder.subfolders?.forEach(flattenData);
  };

  data.forEach(flattenData);

  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <Text style={styles.header}>{title}</Text>
        <View style={styles.table}>
          {/* Header */}
          <View style={[styles.tableRow, { backgroundColor: "#f2f2f2" }]}>
            <Text style={[styles.tableCol, styles.tableCell]}>{columns.accountCode}</Text>
            <Text style={[styles.tableCol, styles.tableCell]}>{columns.description}</Text>
          </View>

          {/* Body */}
          {flatData.map((row, index) => (
            <View key={index} style={styles.tableRow}>
              <Text style={[styles.tableCol, styles.tableCell]}>
                {row[columns.accountCode] || "-"}
              </Text>
              <Text style={[styles.tableCol, styles.tableCell]}>
                {row[columns.description] || "-"}
              </Text>
            </View>
          ))}
        </View>
      </Page>
    </Document>
  );
};