import { useState, useRef, useEffect } from "react";
// import translations from "../../../../../../ui/traslation/translationPLanDeCuentas.json";
import PlanDeCuentasModal from "../../../../../../modals/modalPlanDeCuentas/modalPlanDeCuentas";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import AddIcon from "@mui/icons-material/Add";
import HorizontalRuleIcon from "@mui/icons-material/HorizontalRule";
import EditIcon from "@mui/icons-material/Edit";
import SaveIcon from "@mui/icons-material/Save";
// import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { PlanCuentasApi } from "../../../../../../../../api/planCuentas.api";
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

/**
 * Estructura las carpetas en jerarquías basadas en `codigoFinal`.
 * @param {Array} data - Lista de elementos del plan de cuentas.
 * @returns {Array} Lista jerarquizada de carpetas.
 */
export const structureFolders = (data, showDeleted = false) => {
  const folderMap = new Map();

  // Filtrar cuentas según showDeleted y excluir "Cuenta Otros"
  const filteredData = data.filter(
    (item) => (showDeleted || item.isDeleted === "0") && item.nameFinal !== "Cuenta Otros"
  );

  // Crear el mapa de carpetas
  filteredData.forEach((item) => {
    folderMap.set(item.codigoFinal, { ...item, subfolders: [] });
  });

  const rootFolders = [];

  // Construir la jerarquía
  filteredData.forEach((item) => {
    const level = item.codigoFinal.split("-").filter((num) => num !== "0").length;
    const parentCode = getParentCode(item.codigoFinal, level);

    if (parentCode) {
      const parentFolder = folderMap.get(parentCode);
      if (parentFolder) {
        parentFolder.subfolders.push(folderMap.get(item.codigoFinal));
      } else {
        rootFolders.push(folderMap.get(item.codigoFinal));
      }
    } else {
      rootFolders.push(folderMap.get(item.codigoFinal));
    }
  });

  return rootFolders;
};
/**
 * Obtiene el código del padre basado en el nivel.
 * @param {string} codigoFinal - Código actual de la cuenta.
 * @param {number} level - Nivel del código actual.
 * @returns {string|null} Código del padre, o null si no existe.
 */
export const getParentCode = (codigoFinal) => {
  const parts = codigoFinal.split("-").filter(p => p !== "0");
  
  // Truncar a máximo 4 segmentos y generar código padre
  if (parts.length > 4) parts.length = 4; // Ej: "3-1-5-34" → "3-1-5-34" (pero se ajusta en el siguiente paso)
  
  const parentParts = parts.slice(0, -1); // Remover el último segmento
  if (parentParts.length === 0) return null;
  
  return parentParts.join("-") + "-0".repeat(4 - parentParts.length);
};

const FolderItem = ({ folder, level = 1, isEditMode, businessId, refreshData, showDeleted }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [message, setMessage] = useState("");

  const toggleFolder = () => {
    if (level < 4) {
      setIsOpen((prevState) => !prevState);
    }
  };

  const openModal = () => {
    setIsModalOpen(true);
  };

  const handleSave = async (editedFolderName) => {
    const planCuentasApi = new PlanCuentasApi();
    try {
      if (folder.cuentaCustomId !== null) {
        // Usar el endpoint para cuentas personalizadas
        await planCuentasApi.updateCustomAccount(
          businessId,
          folder.cuentaCustomId, // Usar cuentaCustomId como ID
          editedFolderName,
          false // No eliminar la cuenta
        );
      } else {
        // Usar el endpoint para cuentas estándar
        await planCuentasApi.UpdateCuenta(
          businessId,
          folder.cuentaContableId, // Usar cuentaContableId como ID
          editedFolderName
        );
      }

      setMessage("¡Cambios guardados exitosamente! 🎉");

      if (refreshData) refreshData(); // Refrescar datos tras guardar
    } catch (error) {
      console.error("Error al actualizar la cuenta:", error);
      setMessage("Hubo un error al guardar los cambios.");
    } finally {
      setTimeout(() => setMessage(""), 3000); // Limpia el mensaje tras 3 segundos
    }
  };

  const isMainFolder = level === 1;

  return (
    <div
      style={{ marginLeft: `${level * 16}px`, marginTop: "8px" }}
      className={isMainFolder ? "bg-gray-100 rounded-md p-4" : ""}
    >
      <FolderHeader
        folderName={folder.nameFinal}
        folderId={folder.cuentaCustomId ?? folder.cuentaContableId}
        folderCode={folder.codigoFinal} // Pasar el código de la cuenta
        businessId={businessId}
        isCustom={folder.cuentaCustomId !== null}
        isOpen={isOpen}
        toggleFolder={toggleFolder}
        level={level}
        isEditMode={isEditMode}
        setMessage={setMessage}
        refreshData={refreshData}
        onAddClick={openModal}
        onSave={handleSave}
        isDeleted={folder.isDeleted}
      />

      {isOpen && level < 4 && folder.subfolders?.length > 0 && (
        <FolderList
          subfolders={folder.subfolders}
          level={level + 1}
          isEditMode={isEditMode}
          businessId={businessId}
          refreshData={refreshData}
          showDeleted={showDeleted}
        />
      )}

      {message && (
        <div className="mt-2 p-2 bg-green-100 border border-green-300 rounded text-green-800 text-sm">
          {message}
        </div>
      )}

      {isModalOpen && (
        <PlanDeCuentasModal
          isModalOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
          selectedFolderCode={folder.codigoFinal} // Código del padre
          businessId={businessId} // ID del negocio
          refreshData={refreshData} // Refrescar datos tras agregar
        />
      )}
    </div>
  );
};

const FolderHeader = ({
  folderName,
  folderId,
  folderCode, // Código de la cuenta, como 0-0-0-0
  businessId,
  isCustom, // Indica si la cuenta ya fue personalizada
  isOpen,
  toggleFolder,
  level,
  onAddClick,
  isEditMode,
  setMessage,
  refreshData,
  isDeleted,
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const [editedFolderName, setEditedFolderName] = useState(folderName);
  const [isSaving, setIsSaving] = useState(false);
  const planCuentasApi = new PlanCuentasApi();
  const editRef = useRef(null); // Referencia para detectar clics dentro del componente

  // Manejar clics fuera del componente para desactivar el modo de edición
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (editRef.current && !editRef.current.contains(event.target)) {
        setIsEditing(false); // Salir del modo de edición
      }
    };

    if (isEditing) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside); // Limpieza
    };
  }, [isEditing]);

  const handleReactivateClick = async (e) => {
    e.stopPropagation();
    try {
      await planCuentasApi.reactivateAccounts(businessId, [folderId]); // Restaurar cuenta
      if (refreshData) refreshData(); // Refrescar datos tras la restauración
    } catch (error) {
      console.error("Error al restaurar la cuenta:", error);
      setMessage("Hubo un error al restaurar la cuenta.");
    } finally {
      setTimeout(() => setMessage(""), 3000); // Limpia el mensaje tras 3 segundos
    }
  };

  const handleEditClick = (e) => {
    e.stopPropagation();
    setIsEditing(true); // Activar modo edición
  };

  const handleSaveClick = async (e) => {
    e.stopPropagation();
    setIsSaving(true);

    try {
      if (isCustom) {
        await planCuentasApi.updateCustomAccount(businessId, folderId, editedFolderName, false);
      } else {
        await planCuentasApi.UpdateCuenta(businessId, folderId, editedFolderName);
      }

      if (refreshData) refreshData(); // Refrescar datos tras guardar
      setIsEditing(false);
    } catch (error) {
      console.error("Error al actualizar la cuenta:", error);
      setMessage("Hubo un error al guardar los cambios.");
    } finally {
      setTimeout(() => setMessage(""), 3000); // Limpia el mensaje tras 3 segundos
      setIsSaving(false);
    }
  };

  const handleDeleteClick = async (e) => {
    e.stopPropagation();
    const confirmDelete = window.confirm(
      "¿Seguro que quieres eliminar esta cuenta?."
    );

    if (confirmDelete) {
      try {
        await planCuentasApi.markAccountAsDeleted(businessId, folderCode); // Marcar como eliminada
        if (refreshData) refreshData(); // Refrescar datos tras eliminar
      } catch (error) {
        console.error("Error al eliminar la cuenta:", error);
        setMessage("Hubo un error al eliminar la cuenta.");
      } finally {
        setTimeout(() => setMessage(""), 3000);
      }
    }
  };


  const handleInputChange = (e) => {
    setEditedFolderName(e.target.value); // Actualizar nombre editado
  };

  const shouldShowAddButton = isEditMode && level === 3;
  const shouldShowDelButton = isEditMode && isDeleted === "0" && ( level === 4); // Ocultar "Eliminar" para cuentas eliminadas
  const shouldShowEditOrSaveButton = isEditMode && ( level === 4);
  const shouldShowRestoreButton = isEditMode && isDeleted === "1"; // Mostrar botón de restaurar si la cuenta está eliminada

  const folderStyle = isDeleted === "1" ? "opacity-60 line-through text-red-500" : "text-gray-800"; // Estilo para carpetas eliminadas

  return (
    <div
      className={`flex items-center justify-between ${level === 4 ? "cursor-default" : "cursor-pointer"
        }`}
      ref={editRef} // Referencia al contenedor
      onClick={() => toggleFolder()}
    >
      <div className="flex items-center">
        {level === 4 ? (
          <HorizontalRuleIcon fontSize="small" className="text-gray-600" />
        ) : isOpen ? (
          <KeyboardArrowDownIcon fontSize="small" className="text-gray-600 rotate-90" />
        ) : (
          <KeyboardArrowDownIcon fontSize="small" className="text-gray-600" />
        )}

        {isEditing ? (
          <input
            type="text"
            value={editedFolderName}
            onChange={handleInputChange}
            maxLength={50}
            className="ml-2 text-sm font-medium text-gray-800 border-b border-gray-400 focus:outline-none focus:border-teal-500"
            autoFocus
          />
        ) : (
          <span className={`ml-2 text-sm font-medium ${folderStyle}`}>
            {folderName}
            {isEditMode && (  // Solo muestra el código si está en modo edición
              <span className="text-gray-500 text-xs"> ({folderCode})</span>
            )}
          </span>
        )}
      </div>

      <div className="flex items-center justify-end space-x-2">
        {shouldShowAddButton && (
          <button
            className="transform hover:scale-110 hover:opacity-80 hover:shadow-xl transition-all duration-200"
            title="Add folder"
            onClick={(e) => {
              e.stopPropagation();
              onAddClick();
            }}
          >
            <AddIcon fontSize="small" />
          </button>
        )}
        {shouldShowEditOrSaveButton &&
          (isEditing ? (
            <button
              className={`transform hover:scale-110 hover:opacity-80 hover:shadow-xl transition-all duration-200 ${isSaving ? "opacity-50 cursor-not-allowed" : ""
                }`}
              title="Save folder"
              onClick={(e) => handleSaveClick(e)}
              disabled={isSaving}
            >
              <SaveIcon fontSize="small" />
            </button>
          ) : (
            <button
              className="transform hover:scale-110 hover:opacity-80 hover:shadow-xl transition-all duration-200"
              title="Edit folder"
              onClick={handleEditClick}
            >
              <EditIcon fontSize="small" />
            </button>
          ))}
        {shouldShowDelButton && (
          <button
            className="transform hover:scale-110 hover:opacity-80 hover:shadow-xl transition-all duration-200"
            title="Ocultar"
            onClick={(e) => handleDeleteClick(e)}
          >
            <VisibilityOffIcon fontSize="small" />
          </button>
        )}
        {shouldShowRestoreButton && (
          <button
            className="transform hover:scale-110 hover:opacity-80 hover:shadow-xl transition-all duration-200"
            title="Restore folder"
            onClick={(e) => handleReactivateClick(e)}
          >
            <RemoveRedEyeIcon fontSize="small" />
          </button>
        )}
      </div>
    </div>
  );
};
const FolderList = ({ subfolders, level, isEditMode, businessId, refreshData, showDeleted }) => {
  return (
    <div>
      {subfolders.map((subfolder) => (
        <FolderItem
          key={subfolder.cuentaContableId} // Asegúrate de usar una clave única
          folder={subfolder}
          level={level}
          isEditMode={isEditMode}
          businessId={businessId} // Pasa el businessId a las subcarpetas
          refreshData={refreshData} // Pasa refreshData a las subcarpetas
          showDeleted={showDeleted}
        />
      ))}
    </div>
  );
};

export default FolderItem;