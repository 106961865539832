export const orderableCols = [
    { col: 'Rut', field: 'rut' },
    { col: 'Cliente', field: 'cliente' },
    { col: 'Fecha', field: 'fecha' },
    { col: 'Vencimiento', field: 'vencimiento' },
    { col: 'Comprobante', field: 'comprobante' },
    { col: 'Tipo Comprobante', field: 'tipoComprobante' },
    //{ col: 'Centro Costo', field: 'centroCosto' },
    //{ col: 'Sucursal', field: 'sucursal' },
    { col: 'Documento', field: 'documento' },
    { col: 'Tipo Documento', field: 'tipoDocumento' },
    { col: 'Debe', field: 'debe' },
    { col: 'Haber', field: 'haber' },
    { col: 'Saldo', field: 'Saldo' }
  ]

  export const styles = {
    table: 'min-w-max w-full text-[#000231] text-xs border-collapse leading-6 table-fixed',
    tr: 'hover:bg-gray-100/50',
    td: 'text-left align-top p-4 whitespace-nowrap max-w-52 overflow-x-auto no-scrollbar space-y-1',
    th: 'border-2 border-transparent border-b-gray-200 text-left p-4 whitespace-nowrap bg-white',
    thead: 'sticky top-0 z-10',
    tbody: 'overflow-y-auto',
    boldText: 'font-bold',
    text: 'text-xs text-gray-500'
  }