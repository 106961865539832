import { FC, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { UserAuth } from '../../../../../context/AuthContext';
import { StepForm2 } from './Step2Form';
import { StepForm3 } from './Step3Form';
import { StepForm4 } from './Step4Form';
import { Swiper, SwiperSlide } from 'swiper/react';
//import { SecurityTSL } from '../../components/SignUpLayout';
import { useLucaAuth } from '../hooks/useAuth';

// step forms accountProfile sign up
const stepForms = [
  <StepForm2 key={1} />,
  <StepForm3 key={2} />,
  <StepForm4 key={3} />,
]

/*const stepTitles = [
  {title: 'Dale vida a tu espacio de trabajo',subtitle: '¿Cómo se llamará tu organización?',},
  {title: '¿Qué tipo de rol te define mejor?',subtitle: '',},
  {title: '¡Tu información está segura!',subtitle: 'Utilizamos tecnología avanzada para proteger tus datos',},
]*/

export const CompleteSignUp: FC = () => {
  const navigate = useNavigate()
  const { apiToken } = useLucaAuth((state) => state.accountProfile)
  const { user, isLoaded } = UserAuth()
  const [activeIndex, setActiveIndex] = useState(0)

  useEffect(() => {
    if (!user && !apiToken && isLoaded) {
      navigate('/auth/signup', { replace: true })
    }
  }, [user, apiToken, isLoaded, navigate])
  console.log(activeIndex)
  return (
    <>
      <div className='flex justify-center items-start gap-8'>
        <div className='flex flex-col items-center'>
          {/*<h2 className='text-3xl font-bold mb-3 text-center text-primary'>
            {stepTitles[activeIndex].title}
          </h2>
          {stepTitles[activeIndex].subtitle && (
            <p className='text-2xl font-semibold text-center mb-4'>
              {stepTitles[activeIndex].subtitle}
            </p>
          )}*/}
          
          <div className='relative px-7 py-9 w-[520px] min-h-[460px] bg-white rounded-lg shadow-md'>
            <Swiper
              onSlideChange={(sw) => setActiveIndex(sw?.activeIndex)}
              spaceBetween={30}
              allowTouchMove={false}
            >
              {stepForms.map((step, index) => (
                <SwiperSlide className='mb-1' key={index}>
                  {step}
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </div>
      </div>
    </>
  )
}
