//react
import { useEffect, useState, useContext } from 'react'
import GlobalContext from '../../../../../../../context/global-context'
import { toast } from 'react-toastify'

//api
import { BankAPI } from '../../../../../../../api/bancos.api'

//components
import { Button } from '../../../../../ui/index'
import ModalEditAcounts from '../modalDialogEditAcounts/ModalEditAcounts'
import List from './List'
import { getWidgetToken } from '../../bancos/modalConectFintoc/contectModalStep'

//utils
import { formatCurrency } from '../../../../../../utils/formatter'
import { currentMonth, currentYear } from '../../../../../../utils/constant'

//data
import { firstObjectListAcountBank } from '../data'

const BancosTableInsights = ({ setConnectBank }) => {
  const [openModalEditAcounts, setOpenModalEditAcounts] = useState(false)
  const [deudasPagos, setDeudasPagos] = useState({ porPagar: 0, porCobrar: 0 })
  const [acounts, setAcounts] = useState([])
  const [transactionsAcount, setTransactionsAcount] = useState([])
  const [currentAcount, setCurrentAcount] = useState(acounts[0] || null)
  const { currentBusiness } = useContext(GlobalContext)
  const [uniqueAccounts, setUniqueAccounts] = useState([])
  const [selectedBank, setSelectedBank] = useState('')
  const bankAPI = new BankAPI()

  // Función para identificar la cuenta y el banco de búsqueda
  const handleItemSelection = event => {
    const { value } = event.target
    const selectedAcount = acounts.find(acount => acount.id === parseInt(value))
    setCurrentAcount(selectedAcount)
  }

  console.log(handleItemSelection) //solucion
  const fetchData = async () => {
    try {
      const [listAcountBankResponse] = await Promise.all([
        bankAPI.getListAcountBank(currentBusiness.id)
      ])

      const temporaryListAcountBank = [firstObjectListAcountBank, ...listAcountBankResponse.data]
      getDataListTransactionsAcount(temporaryListAcountBank[0].id)
      setAcounts(temporaryListAcountBank)
    } catch (error) {
      toast.error(
        'No se pudieron cargar los datos 🤯' + JSON.stringify(error.response.data.message)
      )
      console.error('Error:', error)
    }
  }

  useEffect(() => {
    console.log(fetchData)
  }, [])

  const getDataListTransactionsAcount = async (defaultId = null) => {
    try {
      const limit = 100
      const monthFormatter = currentMonth.toString().padStart(2, '0') // Formatea el mes con dos dígitos

      // Llama a `getTransactionsSummary` del API
      const transactions = await bankAPI.getTransactionsSummary(
        defaultId || 0, // Si no hay cuenta seleccionada, usa 0 para todas
        currentBusiness.id, // ID del negocio
        limit, // Límite de registros
        currentYear, // Año actual
        parseInt(monthFormatter) // Mes actual formateado como número
      )
      // Actualiza el estado con los datos recibidos
      setTransactionsAcount(transactions)

      const filteredAccounts = transactions.reduce((acc, transaction) => {
        const exists = acc.some(
          (item) =>
            item.numeroCuentaBanco === transaction.numeroCuentaBanco
        )
        if (!exists) {
          acc.push({
            nombreCuentaBanco: transaction.nombreCuentaBanco,
            numeroCuentaBanco: transaction.numeroCuentaBanco,
            tipoMovimiento: transaction.tipoMovimiento,
            status: transaction.status
          })
        }
          return acc
        }, [])
      setUniqueAccounts(filteredAccounts)
    } catch (error) {
      console.error('Error al obtener las transacciones:', error)
      setTransactionsAcount([])
    }
  }

  useEffect(() => {
    getDataListTransactionsAcount()
  }, [currentBusiness])

  const handleBankSelection = (e) => {
    setSelectedBank(e.target.value)
  }

  // Filtrar transacciones según el banco seleccionado
  const filteredTransactions = selectedBank
  ? transactionsAcount.filter(
      (transaction) =>
        `${transaction.nombreCuentaBanco}-${transaction.numeroCuentaBanco}` === selectedBank
    )
  : transactionsAcount

  // Función para obtener deudas y pagos
  const fetchDeudasPagos = async () => {
    try {
      const response = await bankAPI.getTotalDeudasPagos(currentBusiness.id)
      setDeudasPagos(response.data)
    } catch (error) {
      toast.error('No se pudieron cargar los datos de deudas y pagos 🤯')
      console.error('Error al obtener deudas y pagos:', error)
    }
  }

  // useEffect para cargar las deudas y pagos al iniciar o cuando cambie el negocio actual
  useEffect(() => {
    if (currentBusiness) {
      fetchDeudasPagos()
    }
  }, [currentBusiness])

  const CardInfo = ({ title, amount }) => (
    <section className='flex flex-col shadow border p-4 rounded-2xl bg-white'>
      <h2 className='text-2xl'>{title}</h2>
      <p className='text-[#8E9AB1] text-lg font-bold text-accent2'>{amount ? formatCurrency(amount) : '$ 0'}</p>
      <p className='uppercase text-sm text-accent6'>total</p>
    </section>
  )

  const dataBancos = [
    { title: 'Facturas por pagar', amount: deudasPagos.porPagar },
    { title: 'Facturas por cobrar', amount: deudasPagos.porCobrar },
    { title: 'Saldo en cuenta', amount: currentAcount?.saldo },
  ]

  return (
    <div className='my-10 space-y-6'>
      <div className='grid grid-cols-3 gap-3'>
        {dataBancos.map((item, index) => (
          <CardInfo key={index} title={item.title} amount={item.amount} />
        ))}
      </div>

      <div className='grid grid-cols-3 gap-3'>
        <select
          className='w-full max-w-[650px] appearance-none focus:outline-none focus:ring-0 border border-gray-300 hover:border-gray-400 focus:border-gray-400 px-4 py-2 pr-8 rounded-full shadow leading-tight'
          name='acount'
          onChange={handleBankSelection}
          value={selectedBank}
        >
          <option value=''>Todas las cuentas</option>
          {uniqueAccounts.map((account, index) => (
            <option key={index} value={`${account.nombreCuentaBanco}-${account.numeroCuentaBanco}`}>
              {`${account.nombreCuentaBanco} - ${account.numeroCuentaBanco}`}
            </option>
          ))}
        </select>

        <Button
          onClick={() => getWidgetToken(currentBusiness.id)}
          className='shadow-5xl border-none px-10 py-3 rounded-full uppercase bg-accent2 text-white font-bold text-xs'
          title='Agregar nuevo banco'
        />

        <Button
          onClick={() => setOpenModalEditAcounts(true)}
          className='shadow-5xl border-none px-10 py-3 rounded-full uppercase bg-accent6 text-white font-bold text-xs'
          title='Editar cuentas'
        />
      </div>
      <List
        transactionsAcount={filteredTransactions}
        currentAcount={currentAcount}
        setTransactionsAcount={setTransactionsAcount}
      />
      {openModalEditAcounts && (
        <ModalEditAcounts
          acounts={acounts}
          data={transactionsAcount}
          transactionsAcount={uniqueAccounts}
          open={openModalEditAcounts}
          setOpenModal={setOpenModalEditAcounts}
        />
      )}
    </div>
  )
}

export default BancosTableInsights
