import { useContext, useEffect, useMemo, useState } from 'react'
import GlobalContext from '../../../../../../context/global-context'

//hooks
import { useUpdateDataAdministracion } from '../../../../../../pages/useAdministracion.hooks'

// MUI
import { Backdrop } from '@mui/material'

// components
import { Paper, SearchInput, Text } from '../../../../ui/index'
import { BusinessAPI } from '../../../../../../api/business.api'
import TableEgresos from './TableEgresos'
import Select from '../../../../ui/Select'
import {
  currentMonth,
  currentYear,
  monthsOptions as months,
  yearsOptions
} from '../../../../../utils/constant'
import { ExportCSV, ExportXLS } from '../../../../table/ExportData'
import ExportPDF from '../../../../table/typesExportPDF/ExportPDF'
import { formatCurrency, formatDate, formatRut } from '../../../../../utils/formatter'
import CustomButton from '../../../../ui/CustomButton'
import FilterPagination from '../../../../table/FilterPagination'
import translations from '../../../../ui/traslation/traslationAdministracion.json'
import Loader from '../../../../ui/Loader'

const ListEgresos = () => {
  const [showModal, setShowModal] = useState(false)
  const [data, setData] = useState([])
  const { currentBusiness } = useContext(GlobalContext)
  const [monthFilter, setMonthFilter] = useState(currentMonth)
  const [yearFilter, setYearFilter] = useState(currentYear)
  const [opciones, setOpcionesFilter] = useState([])
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [isLoaderVisible, setIsLoaderVisible] = useState(false)
  const [searchTerm, setSearchTerm] = useState('')
  const [isAprobadoChecked, setIsAprobadoChecked] = useState(false)
  const [isAprobarChecked, setIsAprobarChecked] = useState(false)
  const [checkedDestacados, setCheckedDestacados] = useState(false)
  const [highlightedRows, setHighlightedRows] = useState({})

  const updateData = useUpdateDataAdministracion(state => state.updateData)
  const setUpdateData = useUpdateDataAdministracion(state => state.setUpdateData)

  const getData = async () => {
    const monthFormatter = monthFilter.toString().length === 1 ? `0${monthFilter}` : monthFilter

    if (currentBusiness.id) {
      const businessAPI = new BusinessAPI()
      //ui.setLoader({ visible: true, text: translations.tableAction.loadingDataTable })
      setIsLoaderVisible(true)
      await businessAPI
        .getListSummaryMovements({
          type: 'egreso',
          businessId: currentBusiness.id,
          month: monthFormatter,
          year: yearFilter,
          code: opciones || null ? opciones : data
        })
        .then(({ data }) => {
          setData(data)
          setUpdateData(false)
        })
        .catch(error => {
          setData([])
          console.error(error)
        })
        .finally(() => {
          setIsLoaderVisible(false)
          //ui.setLoader({ visible: false, text: '' })
        })
    } else {
      console.error('No hay un negocio seleccionado')
    }
  }

  const Modal = () => {
    const { show, title, body } = showModal
    if (!show) return null
    return (
      <Backdrop sx={{ zIndex: 1600 }} open={true}>
        <div className='py-6 px-12 flex flex-col items-center justify-center bg-white'>
          <Text variant='h6' className='text-xl mb-4 text-center'>
            {title}
          </Text>
          {body}
          <button
            className='bg-primary text-white rounded-full px-4 py-2 hover:bg-primary/80 focus:outline-none shadow'
            onClick={() => setShowModal(false)}
          >
            Cerrar
          </button>
        </div>
      </Backdrop>
    )
  }

  const dataToExport = data.map(item => ({
    'Nombre folio': item.nombreFolio,
    'Número folio': item.numeroFolio,
    'Razon Social': item.razonSocial,
    RUT: item.rut && formatRut(item.rut),
    Fecha: item.fecha && formatDate(item.fecha),
    'Fecha de vencimiento': item.fechaVencimiento && formatDate(item.fechaVencimiento),
    'Días hasta vencimiento': item.diasHastaVencimiento,
    Clasificación: item.nombreCuenta,
    'Codigo de cuenta': item.codigoCuenta,
    'Número comprobante': item.id,
    Usuario: ' @' + (item.usuarioClasificador || 'Luca'),
    Monto: formatCurrency(item.montoTotal),
    'IVA/RETENIDO': formatCurrency(item.montoIvaRetenido),
    'NETO/LIQUIDO': formatCurrency(item.montoNetoLiquido),
    Status: item.status,
    Aprobado: item.aprobado ? 'Si' : 'No'
  }))

  const icons = [
    <ExportCSV
      key='export-csv'
      currentBusiness={currentBusiness.name}
      rut={currentBusiness.rut}
      data={dataToExport}
      title='Engresos/Honorarios'
      year={yearFilter}
      month={monthFilter}
    />,
    <ExportXLS
      key='export-xls'
      currentBusiness={currentBusiness.name}
      rut={currentBusiness.rut}
      data={dataToExport}
      title='Engresos/Honorarios'
      year={yearFilter}
      month={monthFilter}
    />,
    <ExportPDF
      key='export-pdf'
      currentBusiness={currentBusiness.name}
      rut={currentBusiness.rut}
      data={data}
      setShowModal={setShowModal}
      title='Engresos/Honorarios'
      year={yearFilter}
      month={monthFilter}
    />
  ]

  const monthsOptions = months.map(monthOption => ({
    ...monthOption,
    disabled: yearFilter == currentYear && monthOption.value > currentMonth
  }))
  const filtroDocumento = useMemo(() => {
    const uniqueFolios = [
      { value: '', label: translations.actionsButton.allDocuments },
      ...[...new Set(data.map(item => item.nombreFolio))].map(folio => ({
        value: folio,
        label: folio
      }))
    ]
    return uniqueFolios
  }, [data])

  const filteredData = useMemo(() => {
    let dataFiltrada = data
    if (opciones && opciones.length > 0 && opciones[0] !== '') {
      dataFiltrada = dataFiltrada.filter(item => 
        item.nombreFolio?.trim().toLowerCase() === opciones[0]?.trim().toLowerCase()
      )
    }
    if (searchTerm) {
      const searchLower = searchTerm.toLowerCase();
      dataFiltrada = dataFiltrada.filter(item =>
        item.nombreCuenta?.toLowerCase().includes(searchLower) ||
        item.razonSocial?.toLowerCase().includes(searchLower) ||
        item.montoTotal?.toString().includes(searchLower) ||
        item.nombreFolio?.toLowerCase().includes(searchLower)
      )
    }
    if (isAprobadoChecked) {
      dataFiltrada = dataFiltrada.filter(item => item.aprobado === 1)
    }
    if (isAprobarChecked) {
      dataFiltrada = dataFiltrada.filter(item => item.aprobado === 0)
    }
    if (checkedDestacados) {
      dataFiltrada = dataFiltrada.filter(item => highlightedRows[item.id])
    }
    return dataFiltrada
  }, [opciones, data, searchTerm, isAprobadoChecked, isAprobarChecked, checkedDestacados, highlightedRows])

  useEffect(() => {
    getData()
  }, [currentBusiness])

  useEffect(() => {
    getData()
  }, [updateData === true])

  const handleClearFilters = () => {
    setMonthFilter(currentMonth)
    setYearFilter(currentYear)
    setOpcionesFilter([])
    setSearchTerm('')
    setIsAprobadoChecked(false)
    setIsAprobarChecked(false)
    setCheckedDestacados(false)
  }

  const toggleHighlight = (id) => {
    setHighlightedRows((prev) => {
      const newState = { ...prev, [id]: !prev[id] }
      localStorage.setItem('highlightedRows', JSON.stringify(newState))
      return newState
    })
  }
  
  useEffect(() => {
    const savedHighlights = JSON.parse(localStorage.getItem('highlightedRows')) || {}
    setHighlightedRows(savedHighlights)
  }, [])

  return (
    <>
      <Loader visible={isLoaderVisible} />
      <Paper>
        <div className='relative'>
          <div className='absolute top-[-60px] right-0 z-20 flex space-x-4'>
            {icons.map((icon) => icon)}
          </div>
        </div>
        <div className='flex flex-wrap justify-between items-start mb-4'>
          <div className='flex flex-wrap gap-4 items-start flex-col'>
            <div className='flex gap-2'>
            <FilterPagination rowsPerPage={rowsPerPage} handleChangeRowsPerPage={setRowsPerPage} />
              <Select
                options={monthsOptions}
                defaultValue={monthFilter}
                onChange={(e) => setMonthFilter(e.target.value)}
              />
              <Select
                options={yearsOptions}
                defaultValue={yearFilter}
                onChange={(e) => setYearFilter(e.target.value)}
              />
            </div>
            <div className='flex gap-2'>
              <Select
                options={filtroDocumento}
                defaultValue=''
                onChange={(e) => setOpcionesFilter(e.target.value ? [e.target.value] : [])}
              />
              <CustomButton
                className='rounded-full'
                color='primary'
                title='Buscar'
                onClick={getData}
              />
              <CustomButton
                className='rounded-full'
                color='accent1'
                title='Limpiar'
                onClick={handleClearFilters}
              />
            </div>
          </div>
          <div className='flex flex-col gap-2 w-[250px]'>
            <SearchInput 
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
            <div className='flex gap-4'>
              <div className='flex flex-col'>
                <label>
                  <input
                    className='mr-2 rounded-sm'
                    type='checkbox'
                    checked={checkedDestacados}
                    onChange={() => setCheckedDestacados(!checkedDestacados)}
                  />
                  Destacados
                </label>
              </div>
              <div className='flex flex-col'>
                <label>
                  <input
                    className='mr-2 rounded-sm'
                    type='checkbox'
                    checked={isAprobadoChecked}
                    onChange={() => setIsAprobadoChecked(prev => !prev)}
                  />
                  Aprobado
                </label>
                <label>
                  <input
                    className='mr-2 rounded-sm'
                    type='checkbox'
                    checked={isAprobarChecked}
                    onChange={() => setIsAprobarChecked(prev => !prev)}
                  />
                  Por Aprobar
                </label>
              </div>
            </div>
          </div>
        </div>
        <TableEgresos 
          rows={filteredData} 
          getData={getData} 
          rowsPerPage={rowsPerPage} 
          setRowsPerPage={setRowsPerPage} 
          highlightedRows={highlightedRows}
          toggleHighlight={toggleHighlight}
        />
        <Modal onClose={() => setShowModal(false)} />
      </Paper>
    </>
  )
}

export default ListEgresos
